import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonFileUploadRoutingModule } from './common-file-upload-routing.module';
import { CommonFileUploadComponent } from './common-file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
@NgModule({
  declarations: [CommonFileUploadComponent],
  imports: [
    CommonModule,
    CommonFileUploadRoutingModule,
    FileUploadModule,
    MatProgressBarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatButtonModule,
    CustomFlexLayoutModule
  ],
  exports:[CommonFileUploadComponent]
})
export class CommonFileUploadModule { }
