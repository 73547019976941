<div *ngIf="disableCartIconInHeader==0">
    <button fxFlex *ngIf="(url!='/cart' && url!='/checkout'
    && url!='/checkout/type')"  
    (click)="cart()" mat-button class="flex-row-button">
        <mat-icon  [style.color]="data.headerIconColour"  >add_shopping_cart</mat-icon>
    
        <span *ngIf="cartServices.cartCount>0" class="cart-items-count">
            {{ cartServices.cartCount }}</span>

          <span style="color: black;" class="padding-10">{{CartName}}</span>  
    
            
    </button>
</div>


