<div *ngIf="customField13==0">
  <div fxLayout="row " *ngIf="formInit" fxShow fxHide.lt-md>
    <div fxLayout="row wrap" fxFlex="100" [formGroup]="form" [fxLayoutGap]="gapBetweenImage">

      <ng-container *ngFor="let field of fields">

        <ng-container *ngIf="!field.enableConditionalField || field.visible">

          <ng-container *ngIf="field.inputType !== 'button'; else buttonContainer">
            <div [fxFlex]="layout == 2 ? 
          (gapBetweenImage ?  'calc(50% - ' + (gapBetweenImage) + ')' : '50%') 
          : (layout == 3 ? (gapBetweenImage ? 'calc(33.33% - ' + gapBetweenImage + ')' : '33.33%') 
            : (layout == 4 ? (gapBetweenImage ? 'calc(25% - ' + gapBetweenImage + ')' : '25%') 
              : '100'))">

              <div dynamicField (valueChange)="onFieldValueChange($event)" [field]="field" [group]="form"></div>
            </div>
          </ng-container>
          <ng-template #buttonContainer>
            <div *ngIf="EnablePayment==0 && !enableFormPayment" fxFlex="100">
              <button (click)="onSubmit()" fxFlex type="submit" style="color:white" mat-raised-button
                class="primary">{{field.label}}</button>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>

      <div *ngIf="formValid && enableFormPayment">
        <app-razor-pay [contact]="contact" [partialAmountEnabled]="0" (paymentStatus)="paymentResponceRazorPAy($event)"
          [finalAmount]="amount"></app-razor-pay>

      </div>

    </div>
  </div>
</div>

<div *ngIf="customField13==0">
  <div *ngIf="formInit" fxShow fxHide.gt-sm fxLayout="column">
    <div style="box-sizing: border-box;width: 100%;" [formGroup]="form" [fxLayoutGap]="gapBetweenImage"
      fxLayout="column">

      <ng-container *ngFor="let field of fields" style="width: 100%;">
        <ng-container *ngIf="!field.enableConditionalField || field.visible">
          <ng-container *ngIf="field.inputType !== 'button'; else buttonContainer" style="width: 100%;">
            <div [fxFlex]="layout == 2 ? 
        (gapBetweenImage ?  'calc(50% - ' + (gapBetweenImage) + ')' : '50%') 
        : (layout == 3 ? (gapBetweenImage ? 'calc(33.33% - ' + gapBetweenImage + ')' : '33.33%') 
          : (layout == 4 ? (gapBetweenImage ? 'calc(25% - ' + gapBetweenImage + ')' : '25%') 
            : '100'))">
              <div dynamicField [field]="field" [group]="form"></div>
            </div>
          </ng-container>
          <ng-template #buttonContainer>
            <div *ngIf="EnablePayment==0 && !enableFormPayment" fxFlex="100">
              <button (click)="onSubmit()" fxFlex type="submit" style="color:white" mat-raised-button
                class="primary">{{field.label}}</button>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>

      <div *ngIf="formValid && enableFormPayment">
        <app-razor-pay [contact]="contact" [partialAmountEnabled]="0" (paymentStatus)="paymentResponceRazorPAy($event)"
          [finalAmount]="amount"></app-razor-pay>

      </div>

    </div>
  </div>
</div>

<div *ngIf="customField13==1" fxLayoutAlign="center center">

  <div *ngIf="formInitSecond;else other_content">
    <calsoft-sub-form [layout]="layout" (submit)="submitSecondForm($event)"
      [formGroupId]="newFormField"></calsoft-sub-form>
  </div>

  <ng-template #other_content>

    <div fxLayout="row" class="cards" fxLayoutAlign="center center" *ngIf="formInit">
      <div fxLayout="row wrap" fxFlex="100" [formGroup]="form" [fxLayoutGap]="gapBetweenImage">

        <ng-container>

          <div fxLayout="column">
            <div fxLayout="row wrap" fxLayoutAlign="start start">
              <div *ngFor="let field of valuesInputed; let i = index">

                <div *ngIf="form.value[field]!=null &&form.value[field]!=undefined ">
                  <div *ngIf="form.value[field].length>0">
                    <div *ngIf="!field.includes('image')" class="button-cards" (click)="goToState(i)">
                      {{ form.value[field] }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>



          <ng-container>
            <div [fxFlex]="'100'">
              <button style="border: none; margin-top: 5px;" (click)="back()" *ngIf="dontDisplayBackButton">

                <mat-icon>keyboard_backspace</mat-icon>

              </button>

              <div *ngIf="formInit1">

                <div *ngIf="objectTobeDisplayed.inputType=='image'">

                  <calsoft-image (valueChange)="onFieldValueChange($event)" [field]="objectTobeDisplayed"
                    [group]="form">

                  </calsoft-image>
                </div>


                <div *ngIf="objectTobeDisplayed.inputType=='select'">
                  <calsoft-select (valueChange)="onFieldValueChange($event)" [field]="objectTobeDisplayed"
                    [group]="form">
                  </calsoft-select>
                </div>

                <div *ngIf="objectTobeDisplayed.inputType=='text'">

                  <div>
                    <calsoft-input [customField13]="customField13" (valueChange)="onFieldValueChange($event)"
                      [field]="objectTobeDisplayed" [group]="form">
                    </calsoft-input>

                  </div>




                </div>

                <div *ngIf="objectTobeDisplayed.inputType=='file'">

                  <calsoft-file-upload (valueChange)="onFieldValueChangeImage($event)" [customField13]="customField13"
                    [field]="objectTobeDisplayed" [group]="form">
                  </calsoft-file-upload>



                </div>

                <div *ngIf="objectTobeDisplayed.inputType=='button'">
                  <div fxFlex="100" [formGroup]="form">

                    <ng-container *ngFor="let field of fields">
                      <ng-container *ngIf="field.inputType == 'button'">
                        <button (click)="onSubmit()" fxFlex type="submit" style="color:white" mat-raised-button
                          class="primary">{{field.label}}</button>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

              </div>


            </div>
          </ng-container>

        </ng-container>

      </div>
    </div>
  </ng-template>
</div>

<div class="margin-t10" *ngIf="EnablePayment==1">
  <calsoft-subscription-plan fxFlex="100" [type]="'3'"></calsoft-subscription-plan>
</div>