
<ng-container *ngIf="data.categoryMenuType==2;">


	<div class="theme-container" style="white-space: normal;" *ngIf="display">

		<ng-container *ngFor="let menu1 of categoryArray;">

			<span *ngIf="menu1.displayMode==6; else notButton">

				<calsoft-button-category  [menuData]="menu1" [style.fontSize]="data.categoryFontSize" [data]="data" ></calsoft-button-category>
			</span>

			<ng-template #notButton>

			<span *ngIf="data.categoryImageDisplayHomepage==0">

				<span *ngIf="menu1.subcategories.length > 0">
					<button #button mat-button [matMenuTriggerFor]="panel.menu" #menuTrigger="matMenuTrigger"
						(click)="openMegaMenu()"><span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"
							class="menuSize">{{menu1.categoryName }}</span>
						<mat-icon [style.color]="data.categoryColor" 
							style="font-size: 22px;font-weight: lighter;">keyboard_arrow_down</mat-icon>
					</button>
				</span>

				<span *ngIf="menu1.subcategories.length == 0">
					<a mat-button routerLinkActive="active-link" (click)="goToCategory(menu1)">

						<span [style.color]="data.categoryColor"  [style.fontSize]="data.categoryFontSize" class="menuSize theme-container">{{menu1.categoryName | characterPipe}}</span>
					</a>
				</span>

			</span>




			<span *ngIf="data.categoryImageDisplayHomepage==1">
				<span *ngIf="menu1.subcategories.length > 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'"
						[src]="serverPath + menu1.image" [alt]="menu1.categoryName">
					<button #button mat-button [matMenuTriggerFor]="panel.menu" #menuTrigger="matMenuTrigger"
						(click)="openMegaMenu()"><span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"
							class="menuSize">{{menu1.categoryName  | characterPipe }}</span>
						<mat-icon [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"
							style="font-size: 29px;font-weight: lighter;">keyboard_arrow_down</mat-icon>
					</button>

				</span>

				<span *ngIf="menu1.subcategories.length == 0">
					<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'"
						[src]="serverPath + menu1.image" [alt]="menu1.categoryName">
					<a mat-button routerLinkActive="active-link" (click)="goToCategory(menu1)">

						<span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  class="menuSize theme-container">{{menu1.categoryName | characterPipe}}</span>
					</a>
				</span>

			</span>


			<span>

				<calsoft-menu2 [data]="data" [display]="display" yPosition="below" #panel
					[items$]="menu1.subcategories"></calsoft-menu2>

			</span>
			</ng-template>
		</ng-container>
	</div>


	<div class="backMenu theme-container" fxHide.lt-md *ngIf="!display">

		<mat-toolbar class="top-navbar theme-container backMenu" [color]="color ? 'primary' : null"
			fxLayoutAlign="center center">
			<ng-container class="fill-remaining-space" *ngFor="let menu1 of categoryArray;">
				<span *ngIf="menu1.subcategories.length > 0">
					<a class="hover1" mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
						(click)="openMegaMenu()"><span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"
							class="menuSize">{{menu1.categoryName | characterPipe }}</span>
						<mat-icon [style.color]="data.categoryColor"  [style.fontSize]="data.categoryFontSize" style="font-size: 29px;font-weight: lighter;"
							class="caret">keyboard_arrow_down</mat-icon>
					</a>
				</span>
				<a mat-button *ngIf="menu1.subcategories.length == 0" routerLinkActive="active-link"
					(click)="goToCategory(menu1)">
					<span [style.color]="data.categoryColor"  [style.fontSize]="data.categoryFontSize" class="menuSize">{{menu1.categoryName | characterPipe }}</span>
				</a>
				<span (mouseleave)="menuTrigger.closeMenu()"></span>

				<mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu theme-container app-dropdown">

					<div fxLayout="row wrap" fxLayout.xs="column wrap">

						<div [style.column-count]="menu1.mega != 'false'?data.categroySubmenuColumn:''">


							<span *ngFor="let submenu1 of menu1.subcategories">

								<a (click)="goToCategory(submenu1)" mat-menu-item routerLink="/"><span
										[style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" >{{submenu1.categoryName}}</span>

									<i [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" 
										class="material-icons theme-container subMenus">keyboard_arrow_right</i>

								</a>


								<calsoft-nested-child [data]="data" [type]="'ipad'"
									[list]="submenu1.subcategories"></calsoft-nested-child>

								<!-- <span *ngIf="submenu1.subcategories && submenu1.subcategories.length >0">

								<a *ngFor="let subChild of submenu1.subcategories" (click)="goToCategory(subChild)"
									mat-menu-item routerLink="/"> {{subChild.categoryName}}</a>
							</span> -->


							</span>
						</div>

					</div>

				</mat-menu>
			</ng-container>
		</mat-toolbar>
	</div>


</ng-container>

<ng-container *ngIf="data.categoryMenuType==1;">


	<nav class="app-nav" style="white-space: normal;" *ngIf="display">
		<ul ngClass.lt-lg="catNames" class="app-nav-list">


			<li class="app-nav-item" *ngFor="let menu of categoryArray" [ngClass]="{'mega': menu.mega != 'false'}">


	
				<div *ngIf="menu.displayMode==6; else notButton">

					<calsoft-button-category [menuData]="menu" [data]="data" ></calsoft-button-category>
				</div>

				<ng-template #notButton>
					<span *ngIf="data.categoryImageDisplayHomepage==0">


						<ng-container *ngIf="menu.subcategories.length > 0">
							<a [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  (click)="goToCategory(menu)"
								style="display: flex; align-items: center;font-size: 15px;line-height: 30px;"
								class="pointer" routerLinkActive="active-link">
								{{menu.categoryName | characterPipe}}
								<mat-icon [style.color]="data.categoryColor"  
									style="font-size: 22px;">keyboard_arrow_down</mat-icon>

							</a>
						</ng-container>

						<a [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  class="nav-link  pointer" (click)="goToCategory(menu)"
							*ngIf="menu.subcategories.length == 0" style="font-size: 15px;line-height: 30px;">
							{{menu.categoryName | characterPipe}}
						</a>
					</span>

					<span *ngIf="data.categoryImageDisplayHomepage==1">
						<div style="display: flex; align-items: center;font-size: 15px;line-height: 30px;"
							*ngIf="menu.subcategories.length > 0">
							<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'"
								[src]="serverPath + menu.image" [alt]="menu.categoryName">
							<a [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" class="nav-link pointer"
								(click)="goToCategory(menu)">{{ menu.categoryName | characterPipe }}</a>
						</div>


						<div style="display: flex; align-items: center;font-size: 15px;line-height: 30px;"
							*ngIf="menu.subcategories.length == 0">
							<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'"
								[src]="serverPath + menu.image" [alt]="menu.categoryName">
							<a [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  class="nav-link pointer"
								(click)="goToCategory(menu)">{{ menu.categoryName | characterPipe}}</a>
						</div>
					</span>


					<div class="sub-menu " style="z-index: 1000;max-height: 70vh;overflow-y: auto;"
						*ngIf="menu.subcategories && menu.subcategories.length >0 "
						[ngStyle]="{'width': menu.mega == 'false' ? 'max-content' : ''}">

						<div [style.column-count]="menu.mega != 'false'?data.categroySubmenuColumn:''">
							<div *ngFor="let submenu1 of menu.subcategories">

								<ng-container>
									<div fxLayout="row" style="align-items: center;">
										<span (click)="goToCategory(submenu1)"
											style="font-size: 15px;line-height: 30px;" class="subMenus">
											{{submenu1.categoryName | characterPipe}}
										</span>
										<mat-icon class="subMenus"
											style="vertical-align: middle;font-size: 20px;margin-top: 5px;">keyboard_arrow_right</mat-icon>
									</div>

									<div fxLayout="row" fxLayoutAlign="start center">
										<calsoft-nested-child [data]="data" [type]="'all'"
											[list]="submenu1.subcategories"></calsoft-nested-child>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</ng-template>

			</li>
		</ul>
	</nav>

	<div  *ngIf="!display">

		<mat-toolbar [color]="color ? 'primary' : null" class="top-navbar" fxLayoutAlign="center center">
			<ng-container class="fill-remaining-space" *ngFor="let menu1 of categoryArray;">


				<div *ngIf="menu1.displayMode==6; else notButton">


					<!-- <calsoft-menu-button [data]="menu1"></calsoft-menu-button> -->
				</div>

				<ng-template #notButton>
					<span *ngIf="menu1.subcategories.length > 0">
						<a class="hover1" mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
							(click)="openMegaMenu()"><span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"
								class="menuSize">{{menu1.categoryName | characterPipe}}</span>
							<mat-icon [style.color]="data.categoryColor"
								style="font-size: 29px;font-weight: lighter;">keyboard_arrow_down</mat-icon>
						</a>
					</span>
					<a mat-button *ngIf="menu1.subcategories.length == 0" routerLinkActive="active-link"
						(click)="goToCategory(menu1)">
						<span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  class="menuSize">{{menu1.categoryName | characterPipe}}</span>
					</a>
					<span (mouseleave)="menuTrigger.closeMenu()"></span>

					<mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">

						<div fxLayout="row wrap" fxLayout.xs="column wrap">

							<div [style.column-count]="menu1.mega != 'false'?data.categroySubmenuColumn:''">


								<span *ngFor="let submenu1 of menu1.subcategories">
									<a (click)="goToCategory(submenu1)" mat-menu-item routerLink="/" style="display: flex; align-items: center;">
									  <span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" style="flex-grow: 1;">
										{{ submenu1.categoryName | characterPipe }}
									  </span>
									  <i [style.color]="data.categoryColor" class="material-icons subMenus" style="margin-left: 8px;">
										keyboard_arrow_right
									  </i>
									</a>
								  
									<calsoft-nested-child [data]="data" [type]="'ipad'" [list]="submenu1.subcategories"></calsoft-nested-child>
								  </span>
								  
							</div>

						</div>

					</mat-menu>
				</ng-template>
			</ng-container>
		</mat-toolbar>
	</div>

</ng-container>

<ng-container *ngIf="data.categoryMenuType==3;">
	<div class="topnav">
		<div class="dropdown" *ngFor="let menu of categoryArray">

			<span *ngIf="menu.displayMode==6; else notButton" [style.fontSize]="data.categoryFontSize"  >

				<calsoft-button-category  [menuData]="menu" [data]="data" ></calsoft-button-category>

			</span>

			<ng-template #notButton>
			<span fxLayoutAlign="center center" (click)="goToCategory(menu)" [style.color]="data.categoryColor"   [style.fontSize]="data.categoryFontSize"  >{{menu.categoryName}}
				<mat-icon [style.color]="data.categoryColor"  style="font-size: 22px;font-weight: lighter;"
					*ngIf="menu.subcategories.length > 0">keyboard_arrow_down</mat-icon>
			</span>

			<div class="dropdown-content contentdrop1"
				[ngStyle]="{'left': menu.mega === 'false' ? 'auto' : '2%', 'width': menu.mega === 'false'? '100px' : 'auto'}"
				*ngIf="menu.subcategories.length > 0">
				<div class="header">
					<div fxLayout="row">
						<div fxLayout-align="center center" fxLayout="column"
							*ngFor="let submenu1 of menu.subcategories;let odd = odd;let even = even"
							[ngClass]="{'odd-column': odd, 'even-column': even}">
							<span class="menu-sub1" style="border-bottom: none;"
								(click)="goToCategory(submenu1)"  [style.fontSize]="data.categoryFontSize"  ><b>{{submenu1.categoryName }}</b></span>
							<div>
								<calsoft-nested-child [data]="data" [type]="'all'"
									[list]="submenu1.subcategories"   [style.fontSize]="data.categoryFontSize"   ></calsoft-nested-child>
							</div>
						</div>
					</div>
				</div>
			</div>

			</ng-template>
		</div>
	</div>


</ng-container>

<ng-container *ngIf="data.categoryMenuType==4;">
	<div class="topnav">
		<div class="dropdown" *ngFor="let menu of categoryArray">

			<div *ngIf="menu.displayMode==6; else notButton">
				<calsoft-button-category [menuData]="menu" [data]="data" ></calsoft-button-category>
			</div>

			<ng-template #notButton>
				<span fxLayoutAlign="center center" (click)="goToCategory(menu)" [style.color]="data.categoryColor"   [style.fontSize]="data.categoryFontSize"  >{{menu.categoryName}}
					<mat-icon [style.color]="data.categoryColor"  
						*ngIf="menu.subcategories.length > 0" style="font-size: 24px;font-weight: lighter;"   >arrow_drop_down</mat-icon>
				</span>

				<div class="dropdown-content contentdrop1"
					[ngStyle]="{ 'width': menu.mega === 'false'? 'max-content' : '100%'}"
					*ngIf="menu.subcategories.length > 0" style="box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);">
					<div class="header" style="  padding-left: 10%;padding-right: 10%;">
						<div fxLayout="column">
							<div fxLayout-align="center center" fxLayout="column"
								*ngFor="let submenu1 of menu.subcategories;let odd = odd" style="padding: 10px;">
								<span *ngIf="submenu1.subcategories.length > 0" class="menu-sub1"
									style="border-bottom: none;"  [style.fontSize]="data.categoryFontSize"  ><b> {{submenu1.categoryName }}</b></span>
								<span *ngIf="submenu1.subcategories.length == 0" class="menu-sub1 "
									(click)="goToCategory(submenu1)"
									style="border-bottom: none;"  [style.fontSize]="data.categoryFontSize"  ><b>{{submenu1.categoryName }}</b></span>
								<div>
									<calsoft-nested-child [data]="data" [type]="'all'"
										[list]="submenu1.subcategories"  [style.fontSize]="data.categoryFontSize"  ></calsoft-nested-child>
								</div>
							</div>
						</div>
					</div>
				</div>

			</ng-template>
		</div>
	</div>

</ng-container>

<ng-container *ngIf="data.categoryMenuType==5;">


	<nav class="app-nav" style="white-space: normal;" *ngIf="display">
		<ul ngClass.lt-lg="catNames" class="app-nav-list">


			<li class="app-nav-item" *ngFor="let menu of categoryArray" [ngClass]="{'mega': menu.mega != 'false'}">


				<div *ngIf="menu.displayMode==6; else notButton">

					<calsoft-button-category [menuData]="menu" [data]="data"  ></calsoft-button-category>




				</div>

				<ng-template #notButton>
					<span *ngIf="data.categoryImageDisplayHomepage==0">


						<ng-container *ngIf="menu.subcategories.length > 0">
							<a [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  (click)="goToCategory(menu)"
								style="display: flex; align-items: center;font-size: 15px;line-height: 30px;"
								class="pointer" routerLinkActive="active-link">
								{{menu.categoryName}}
								<mat-icon [style.color]="data.categoryColor" 
									style="font-size: 24px;font-weight: lighter;">keyboard_arrow_down</mat-icon>

							</a>
						</ng-container>

						<a [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  class="nav-link  pointer" (click)="goToCategory(menu)"
							*ngIf="menu.subcategories.length == 0" style="font-size: 15px;line-height: 30px;">
							{{menu.categoryName }}
						</a>
					</span>

					<span *ngIf="data.categoryImageDisplayHomepage==1">
						<div style="display: flex; align-items: center;font-size: 15px;line-height: 30px;"
							*ngIf="menu.subcategories.length > 0">
							<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'"
								[src]="serverPath + menu.image" [alt]="menu.categoryName">
							<a [style.color]="data.categoryColor"  [style.fontSize]="data.categoryFontSize" class="nav-link pointer"
								(click)="goToCategory(menu)">{{ menu.categoryName }}</a>
						</div>


						<div style="display: flex; align-items: center;font-size: 15px;line-height: 30px;"
							*ngIf="menu.subcategories.length == 0">
							<img class="images" onerror="this.src='assets/images/error/no-picture-icon.jpg'"
								[src]="serverPath + menu.image" [alt]="menu.categoryName">
							<a [style.color]="data.categoryColor"  [style.fontSize]="data.categoryFontSize" class="nav-link pointer"
								(click)="goToCategory(menu)">{{ menu.categoryName }}</a>
						</div>
					</span>


					<div class="sub-menu submenu-css" *ngIf="menu.subcategories && menu.subcategories.length >0 "
						[ngStyle]="{'width': menu.mega == 'false' ? '18%' : ''}">

						<div [style.column-count]="menu.mega != 'false'?data.categroySubmenuColumn:''">
							<div *ngFor="let submenu1 of menu.subcategories">

								<ng-container>
									<div fxLayout="row" style="align-items: center;">
										<span (click)="goToCategory(submenu1)"  [style.fontSize]="data.categoryFontSize"   class="subMenus2">
											{{submenu1.categoryName}}
										</span>
										<!-- <mat-icon style="vertical-align: middle;font-size: 20px;margin-top: 5px;">keyboard_arrow_right</mat-icon> -->
									</div>

									<div fxLayout="row" fxLayoutAlign="start center">
										<calsoft-nested-child [data]="data" [type]="'all'"
											[list]="submenu1.subcategories"  [style.fontSize]="data.categoryFontSize"  ></calsoft-nested-child>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</ng-template>

			</li>
		</ul>
	</nav>

	<div fxHide.lt-md *ngIf="!display">
		<mat-toolbar [color]="color ? 'primary' : null" class="top-navbar" fxLayoutAlign="center center">
			<ng-container class="fill-remaining-space" *ngFor="let menu1 of categoryArray;">


				<div *ngIf="menu1.displayMode==6; else notButton">


					<!-- <calsoft-menu-button [data]="menu1"></calsoft-menu-button> -->
				</div>

				<ng-template #notButton>
					<span *ngIf="menu1.subcategories.length > 0">
						<a class="hover1" mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
							(click)="openMegaMenu()"><span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" 
								class="menuSize">{{menu1.categoryName}}</span>
							<mat-icon [style.color]="data.categoryColor"
								style="font-size: 29px;font-weight: lighter;">keyboard_arrow_down</mat-icon>
						</a>
					</span>
					<a mat-button *ngIf="menu1.subcategories.length == 0" routerLinkActive="active-link"
						(click)="goToCategory(menu1)">
						<span [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  class="menuSize">{{menu1.categoryName}}</span>
					</a>
					<span (mouseleave)="menuTrigger.closeMenu()"></span>

					<mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">

						<div fxLayout="row wrap" fxLayout.xs="column wrap">

							<div [style.column-count]="menu1.mega != 'false'?data.categroySubmenuColumn:''">


								<span *ngFor="let submenu1 of menu1.subcategories">

									<a (click)="goToCategory(submenu1)" mat-menu-item routerLink="/"><span
											[style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" >{{submenu1.categoryName}}</span>

										<i [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" 
											class="material-icons subMenus">keyboard_arrow_right</i>

									</a>


									<calsoft-nested-child [data]="data" [type]="'ipad'"
										[list]="submenu1.subcategories"></calsoft-nested-child>

									<!-- <span *ngIf="submenu1.subcategories && submenu1.subcategories.length >0">
  
								  <a *ngFor="let subChild of submenu1.subcategories" (click)="goToCategory(subChild)"
									  mat-menu-item routerLink="/"> {{subChild.categoryName}}</a>
							  </span> -->


								</span>
							</div>

						</div>

					</mat-menu>
				</ng-template>
			</ng-container>
		</mat-toolbar>
	</div>

</ng-container>

<ng-container *ngIf="data.categoryMenuType==6;">
	<div class="topnav1">
		<div class="dropdown" *ngFor="let menu of categoryArray" >

			<div *ngIf="menu.displayMode==6; else notButton">

				<calsoft-button-category *ngIf="!referer.includes('inbamhospitals.')" [menuData]="menu" [data]="data" > </calsoft-button-category>
				<calsoft-button-category-2  *ngIf="referer.includes('inbamhospitals.')" [menuData]="menu"  [data]="data" ></calsoft-button-category-2>
			</div>

			<ng-template #notButton>
			<span class="topnav2" fxLayoutAlign="center center" (mouseover)="onMouse_Over(menu)"  [style.fontSize]="data.categoryFontSize"   (click)="menu.displayOnlyImageInMenu==1 ? NavigationNone() : goToCategory(menu)">{{menu.categoryName}}
				<mat-icon 
					*ngIf="menu.subcategories.length > 0"  style="font-size: 24px;font-weight: lighter;"  >keyboard_arrow_down</mat-icon>
			</span>

			<div class="dropdown-content contentdrop1"
				[ngStyle]="{'left': menu.mega === 'false' ? 'auto' : '5%', 'right':
				 menu.mega === 'false' ? 'auto' : '5%', 'width': menu.mega === 'false'? 'auto' : 'auto'}"
				*ngIf="menu.subcategories.length > 0">
				<div class="header" >
					<span  class="tabs" fxLayout="row wrap" [fxLayoutAlign]="menu.textAlign!=undefined ? menu.textAlign : 'start start'" fxLayoutGap="16px">
						<!-- <div *ngFor="let submenu1 of menu.subcategories" [fxFlex]="100/data.categroySubmenuColumn" > -->
							<div *ngFor="let submenu1 of menu.subcategories">
						  <div>
							<div *ngIf="submenu1.displayOnlyImageInMenu != undefined && submenu1.displayOnlyImageInMenu == 1; else noImage">
							
							

							  <div [class.active]="activeTab === submenu1.categoryName"   (click)="setActiveTabs(submenu1.categoryName)">

								<div  *ngIf="activeTab === submenu1.categoryName">
									<div  [class.active]="activeTab === submenu1.categoryName" [style.fontSize]="data.categoryFontSize" [ngStyle]="{'color': data.categoryColor, 'text-align': 'center', 'border-bottom': '2px solid ' + data.categoryColor}"
									class="subMenus pointer theme-container"  fxFlex="none">{{submenu1.categoryName}}</div>

								</div>

								<div  *ngIf="activeTab != submenu1.categoryName">
									<div  [class.active]="activeTab === submenu1.categoryName" [style.fontSize]="data.categoryFontSize" [ngStyle]="{'color': 'data.categoryColor', 'text-align': 'center'}"
									class="subMenus pointer theme-container"  fxFlex="none">{{submenu1.categoryName}}</div>

								</div>
								

								
							
							  </div>
							</div>
					  
							<ng-template #noImage>
							  <div>
								<div fxLayout="row" fxLayoutAlign="start center">
									<img   *ngIf="menu.displayImageInMenu!=undefined&&menu.displayImageInMenu==1;"  style="width: 30px;" onerror="this.src='assets/images/error/no-picture-icon.jpg';" [src]="serverPath + submenu1.image" [alt]="submenu1.categoryName">
									<div  (click)="goToCategory(submenu1)"  [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize"  fxFlex="none">{{submenu1.categoryName}}</div>
								  </div>
								<div fxLayout="row wrap" fxLayout.xs="column wrap">
								  <calsoft-nested-child [data]="data"  [style.fontSize]="data.categoryFontSize"   [displayImageInMenu]="menu.displayImageInMenu!=undefined?menu.displayImageInMenu:0" [type]="'ipad'" [list]="submenu1.subcategories"></calsoft-nested-child>
								</div>
							  </div>
							</ng-template>
						  </div>
						</div>
					</span>
				  
					 <ng-container   *ngFor="let submenu1 of menu.subcategories;">

					
					  <div *ngIf="submenu1.displayOnlyImageInMenu!=undefined&&submenu1.displayOnlyImageInMenu==1">
						<div class="tab-content" *ngIf="activeTab === submenu1.categoryName">
							<calsoft-nested-child [data]="data" [type]="'image'" [submenu]="submenu1"
							[list]="submenu1.subcategories"  [style.fontSize]="data.categoryFontSize"  ></calsoft-nested-child>
							
						  </div>
						
					</div>
	
					
					  
					</ng-container>
				  </div>
			</div>
			</ng-template>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="data.categoryMenuType==7;">
	<div class="topnav1">
		<div class="dropdown" *ngFor="let menu of categoryArray">

			<div *ngIf="menu.displayMode==6; else notButton">

				<calsoft-button-category [menuData]="menu" [data]="data" ></calsoft-button-category>
				
			</div>

			<ng-template #notButton>

			<span fxLayoutAlign="center start" (click)="goToCategory(menu)"  [style.fontSize]="data.categoryFontSize"  >{{menu.categoryName}}
				<mat-icon [style.color]="data.categoryColor"  
					*ngIf="menu.subcategories.length > 0" style="font-size: 29px;font-weight: lighter;">keyboard_arrow_down</mat-icon>
			</span>

			<div class="dropdown-content contentdrop1"
				[ngStyle]="{'left': menu.mega === 'false' ? 'auto' : '5%', 'right': menu.mega === 'false' ? 'auto' : '5%', 'width': menu.mega === 'false'? 'auto' : 'auto'}"
				*ngIf="menu.subcategories.length > 0">

				<div class="header">
					<div class="tabs">
						<div *ngFor="let submenu1 of menu.subcategories; let k=index">

							<div [class.active]="k==defaultIndex" (click)="setActiveTab(k)"  [style.fontSize]="data.categoryFontSize"  >
								{{submenu1.categoryName}}
							</div>

							<div *ngIf="k==defaultIndex">

								<div *ngIf="initImage">
									<div fxFlex="100" fxLayout="row">
										<calsoft-nested-child [data]="data" [type]="'ipad'"  [style.fontSize]="data.categoryFontSize"  
											[list]="submenu1.subcategories"></calsoft-nested-child>
									</div>

								</div>


							</div>
						</div>
					</div>

				</div>
			</div>
			</ng-template>
		</div>
	</div>


</ng-container>

<ng-container *ngIf="data.categoryMenuType==8;">

	<calsoft-menu-iteration3    [data]="data" [categoryArray]="categoryArray">

	</calsoft-menu-iteration3>
</ng-container>


<ng-container *ngIf="data.categoryMenuType==9;">
	<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
		<!-- Main Menu -->
		<div fxFlex="60%" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
			<div *ngFor="let menu of categoryArray | slice:8:12" class="menu-item" (mouseenter)="openSubMenu(menu)"
				(mouseleave)="closeSubMenu()">
				<!-- Main menu item -->
				<div  (click)="goToCategory(menu)">{{ menu.categoryName }}</div>

				<!-- Submenu - display only if the respective menu has subcategories -->
				<div *ngIf="activeMenu === menu && menu.subcategories.length > 0" class="submenu-container-new"
					fxLayout="row" fxLayoutAlign="start center" (mouseenter)="keepSubMenuOpen()"
					(mouseleave)="closeSubMenu()" fxFlex>
					<div *ngFor="let subMenu of menu.subcategories" class="submenu-item">
						<div class="submenu-item-content"  (click)="goToCategory(subMenu)">
							<div class="submenu-icon-container">
								<img [src]="subMenu.icon" alt="{{ subMenu.categoryName }}" class="submenu-icon">
							</div>
							<div class="submenu-text-container">
								<div class="submenu-category-name">{{ subMenu.categoryName }}</div>
								<div class="submenu-category-description">{{ subMenu.description }}</div>
							</div>
						</div>
					</div>


				</div>
			</div>
		</div>
	</div>










</ng-container>
