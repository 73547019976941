import { Component, DoCheck, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'calsoft-HeaderUserProfileDropdown',
  templateUrl: './HeaderUserProfileDropdown.component.html',
  styleUrls: ['./HeaderUserProfileDropdown.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class HeaderUserProfileDropdownComponent implements OnInit{
   username: any;
   @Input() type: string="normal";
   @Input() color: string="black";
   @Input() data: any;
  
     url: string;
  
     displayNameFully:any=false;

   constructor(private router: Router,
    private userService: UserService,
    private snackBar:MatSnackBar,
    private cartServices:CartService,

    ) { }


   ngOnInit() {
    
    
     this.url=this.router.url;
if(this.data!=undefined){
  if(this.data['displayAccountName']!=undefined){
  this.displayNameFully=this.data['displayAccountName']=="1"?true:false;
    this.username = this.userService.getAuthenticatedUser();
  }
}

   }


   signOut() {
   
    this.userService.logout();
    
    localStorage.removeItem(Constant.CART_COUNT)
  
    this.cartServices.responseCache1 = new Map();
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])

     if (authourized==null||guestLoginEnabled=="1") {
    this.cartServices.getCount1();
     }
     this.cartServices.responseCache1 = new Map();

  }


}
