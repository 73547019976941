import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

const SERVER = environment.HostUrl;
//const SERVER = 'https://saga.calsoftgroup.com';
//  const SERVER = 'http://localhost:5002';


@Injectable({
  providedIn: 'root'
})
export class WssService {
  private socket!: Socket;

  constructor(public shared: SharedService,private http: HttpClient) { }

  connectWithSocketIOServer() {
    this.socket = io(SERVER);

 

    this.socket.on('connect', () => {
      console.log('Successfully connected with Socket.io server');
      localStorage.setItem("socketId",this.socket.id)
    });

    this.socket.on('room-id', (data) => {
      this.shared.sendDataPost( {Name:"room-id", Value:data.roomId});  
    });

    this.socket.on('room-update', (data) => {
      this.shared.sendDataPost( {Name:"participant", Value:data.connectedUsers});  
    });

    this.socket.on("conn-prepare", (data) => {
      //      const { connUserSocketId } = data;
      this.shared.sendDataPost( {Name:"prepareNewPeerConnection", Value1:data.connUserSocketId, Value2:false});  
      this.socket.emit("conn-init", { connUserSocketId: data.connUserSocketId });
    });

    this.socket.on("kick-out-user", (data) => {
      console.log(data);

      console.log("kick-out");
      
      this.shared.sendDataPost( {Name:"kickout", Value1:'disconnect', Value2:true});  
     });

     this.socket.on("mic-off", (data) => {

      
      this.shared.sendDataPost( {Name:"micoff", Value1:'micOff', Value2:true});  
     });
   
  
    this.socket.on("conn-init", (data) => {
     this.shared.sendDataPost( {Name:"prepareNewPeerConnection", Value1:data.connUserSocketId, Value2:true});  
    });
  

    this.socket.on("conn-signal", (data) => {
     this.shared.sendDataPost( {Name:"handleSignalingData", Value:data});  
    });
  
    this.socket.on("user-disconnected", (data) => {
      console.log(data);
      
      this.shared.sendDataPost( {Name:"removePeerConnection", Value:data});        
    });

    this.socket.on("join-request", (data) => {
      this.shared.sendDataPost( {Name:"join-request", Value:data});        
    });

    this.socket.on("disconnect-user", (data) => {

      console.log("disconnect");
      
      console.log('disconnect-user');
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    });

     // Listen for mic state changes from other users
     this.socket.on('micStateChanged', (data) => {
      this.shared.sendDataPost({ Name: 'updateMicState', Value: data });
    });

    
  }

  emit(event: string, data: any): void {
    this.socket.emit(event, data);  
  }

  on(event: string, callback: (data: any) => void) {
    this.socket.on(event, callback);
  }

  getSocketId(): string {
    return this.socket.id;
  }

  disconnect() {
    this.socket.disconnect();
    //  const siteUrl = window.location.origin;
    //  window.location.href = siteUrl;
  }

  createNewRoom(identity:any, roomId:any){
    roomId=localStorage.getItem("roomId");
    const data = {
      identity,
      roomId
    };
  
    this.socket.emit("create-new-room", data);
  }

  joinRoom(identity:any, roomId:any){
    console.log(identity+' '+roomId);
    const data = {
      roomId,
      identity,
    };
  
    this.socket.emit("join-room", data);
  }

  signalPeerData (data:any) {
    this.socket.emit("conn-signal", data);
  };

  send(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  disconnectUser() {
    this.socket.emit('custom-disconnect');
    this.disconnect();
  }


  
  // sendMessage(data:any) {
  //   this.socket.emit('sendMessage', data);
  //   const time = new Date().toLocaleString('en-US', {
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: true,
  //   });
  //  // this.messages.push({ from: data.identity, message: data.message, time: time });
  //  // this.msgData = '';
  // }

  sendMessage(roomId: string, message: string, sender: string) {
    this.socket.emit('chat-message', { roomId, message, sender });
  }

  getMessages() {
    return new Observable(observer => {
      this.socket.on('chat-message', (data) => {
        observer.next(data);
      });
    });
  }

  getChatHistory(roomId: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.videoSocketUrl}/chat-history/${roomId}`);
  }

// Method to emit mic state change
  sendMicStateChange(micOn: boolean) {
    this.socket.emit('micStateChanged', { socketId: this.socket.id, micOn });
  }



  getConnectedUsers(identity: string): Observable<any> {
    const params = new HttpParams().set('identity', identity);
    return this.http.get<any>(`${environment.videoSocketUrl}/connected-users`, { params });
  }

}
