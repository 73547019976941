import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionPlanRoutingModule } from './subscription-plan-routing.module';
import { SubscriptionPlanComponent } from './subscription-plan.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { PaymentGateWayModule } from 'src/app/pages/payment-gate-way/payment-gate-way.module';
import { DirectiveModule } from '../utils/directive/directive.module';
import { PipesModule } from 'src/app/theme/pipes/pipes.module';


@NgModule({
  declarations: [
    SubscriptionPlanComponent
  ],
  imports: [
    CommonModule,
    SubscriptionPlanRoutingModule,
    CustomFlexLayoutModule,
    PaymentGateWayModule,
    DirectiveModule,
    PipesModule,
  ],
  exports:[
    SubscriptionPlanComponent
  ]
})
export class SubscriptionPlanModule { }
