<div fxHide.lt-md>
    <div style="background-color:#242c42; padding: 40px 0px;" >
        <div class="container">
            <div  fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="20px" style="padding-bottom:50px ;">
                <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="50" fxLayoutGap="20px">
                    <div>
                        <div fxLayout="column" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="20px">
                            <h3 >{{data.storeName}}</h3>
                            <div>
                                <p >Yennarkay R. Ravindran Thilagavathy Vidhyasala Matriculation Higher
                                    Secondary School</p>
                                <div>
                                    <ul>
                                        <li class="bottonborder" fxLayoutAlign="start start">
                                            <mat-icon class="thumb">mail</mat-icon><a style="border-top-style:1px solid grey ;" href="#"> {{data.smtpUsername}}</a>
                                        </li>
                                        <li class="bottonborder" fxLayoutAlign="start start">
                                            <mat-icon class="thumb"> local_phone</mat-icon><a href="#">{{ data.storePhoneNumber }}</a>
                                        </li>
                                        <li class="bottonborder" ><a href="#">
                                                <div fxLayoutAlign="start start">
                                                     <span>
                                                            <mat-icon class="thumb"> location_on</mat-icon>
                                                        </span>{{data.streetAddress}}
                                                </div>
    
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="20px">
                        <div>
                            <h3 style="margin-bottom:20px ;">USEFUL LINKS</h3>
                            <div>
                                <div>
                                    <div fxLayout="row" fxLayoutGap="20px">
                                        <div>
                                            <ul>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="">Home</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/YRTV">YRTV</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/history">History</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/accreditation">Accreditation</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Achievements">Achievements</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Sports">Sports</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div fxLayoutGap="20px">
                                            <ul>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Amenities">Amenities</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Faculties">Faculties</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Curriculum">Curriculum</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/News%20and%20Events">News
                                                        &amp;
                                                        Events</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Club%20Activities">Club
                                                        Activities</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Academics">Academics</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Gallery">Gallery</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-around start " fxFlex="50">
                    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="20px">
                        <div fxFlex>
                            <h3 style="margin-bottom:20px ;">GALLERY</h3>
                            <div>
                                <div fxLayoutGap="5px">
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery">
                                        <img src="https://www.yrtvschool.com/assets/images/gallery/junior-block-yrtv-sivakasi.jpg"
                                            alt="Junior Campus" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery">
                                        <img src="https://www.yrtvschool.com/assets/images/gallery/auditorium-yrtv-school-sivakasi.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/kg-class-room-sivakasi-yrtv-school.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/maths-lab-yrtv.jpg" alt=""
                                            class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/chess-class-sivakasi.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/pre-kg-block-yrtv.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/sivakasi-library.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery">
                                        <img src="https://www.yrtvschool.com/assets/images/gallery/sivakasi-yrtv-school-dining-hall-students.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery">
                                        <img src="https://www.yrtvschool.com/assets/images/gallery/sivakasi-yrtv-school-physics-lab.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                </div>
    
                                <a href="https://yrtvschool.dspeedup.in/pages/Gallery" fxLayoutAlign="start start" class="view-more">View more&nbsp;
                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="40">
                        <div fxLayout="column" fxLayoutAlign="start start">
                            <h3 style="margin-bottom:20px ;">Principal's Message</h3>
                            <div fxLayout="row">
                                <div>
                                  <p><img src="https://www.yrtvschool.com/images/yrtv-principal.jpg" alt=""
                                    style="width:50%; height: 50%; float: left; margin-right: 10px;"> Dream as high as you can, as best dreams happen if you keep working at it. Make each
                                    day your master piece. Manifest plainness, embrace simplicity and give up selfishness.
                                    "He
                                    who sows virtue reaps great honour".<br>- Mr. L. MURALI
    
                                  </p>  
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
            <div class="bottonborder"fxLayout="row" fxLayoutAlign="space-between center">
                <div  fxLayout="row" fxLayoutAlign="start start">
                    <ul fxLayout="row" fxLayoutGap="10px"  style="font-size:10px ;">
                        <li><a href="https://yrtvschool.dspeedup.in/pages/YRTV">HOME</a></li>
                        <li><a href="https://yrtvschool.dspeedup.in/pages/YRTV">YRTV</a></li>
                        <li><a href="https://yrtvschool.dspeedup.in/pages/Gallery">GALLERY</a></li>
                        <li><a href="https://yrtvschool.dspeedup.in/pages/Achievements">ACHIEVEMENTS</a></li>
                        <li><a href="">CONTACT</a></li>
                    </ul>
                </div>
                <div fxLayoutAlign="start end" style="font-size:14px ;">
                    <a href="" target="blank" style="color: #337ab7;text-decoration: none;">NWSIPL</a>
                </div>
            </div>
        </div>
    </div>
    
</div>

<div fxHide fxShow.lt-md>
    <div style="background-color:#242c42; padding: 40px 0px;" >
        <div class="container">
            <div  fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="20px" style="padding-bottom:50px ;">
                <div fxLayout="column" fxLayoutAlign="space-around start" fxFlex="100" fxLayoutGap="20px">
                    <div>
                        <div fxLayout="column" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="20px">
                            <h3 >{{data.storeName}}</h3>
                            <div>
                                <p >Yennarkay R. Ravindran Thilagavathy Vidhyasala Matriculation Higher
                                    Secondary School</p>
                                <div>
                                    <ul>
                                        <li class="bottonborder" fxLayoutAlign="start start">
                                            <mat-icon class="thumb">mail</mat-icon><a style="border-top-style:1px solid grey ;" href="#">{{data.smtpUsername}}</a>
                                        </li>
                                        <li class="bottonborder" fxLayoutAlign="start start">
                                            <mat-icon class="thumb"> local_phone</mat-icon><a href="#">{{ data.storePhoneNumber }}</a>
                                        </li>
                                        <li class="bottonborder" ><a href="#">
                                                <div fxLayoutAlign="start start">
                                                     <span>
                                                            <mat-icon class="thumb"> location_on</mat-icon>
                                                        </span>{{data.streetAddress}}
                                                </div>
    
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="20px">
                        <div>
                            <h3 style="margin-bottom:20px ;">USEFUL LINKS</h3>
                            <div>
                                <div>
                                    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px">
                                        <div>
                                            <ul>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/YRTV">Home</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/YRTV">YRTV</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/history">History</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/accreditation">Accreditation</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Achievements">Achievements</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Sports">Sports</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div fxLayoutGap="20px">
                                            <ul>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Amenities">Amenities</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Faculties">Faculties</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Curriculum">Curriculum</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/News%20and%20Events">News
                                                        &amp;
                                                        Events</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Club%20Activities">Club
                                                        Activities</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Academics">Academics</a>
                                                </li>
                                                <li fxLayoutAlign="start start">
                                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon><a
                                                        href="https://yrtvschool.dspeedup.in/pages/Gallery">Gallery</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-around start " fxFlex="100">
                    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start" fxLayoutGap="20px">
                        <div fxFlex>
                            <h3 style="margin-bottom:20px ;">GALLERY</h3>
                            <div>
                                <div fxLayoutGap="5px">
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery">
                                        <img src="https://www.yrtvschool.com/assets/images/gallery/junior-block-yrtv-sivakasi.jpg"
                                            alt="Junior Campus" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery">
                                        <img src="https://www.yrtvschool.com/assets/images/gallery/auditorium-yrtv-school-sivakasi.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/kg-class-room-sivakasi-yrtv-school.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/maths-lab-yrtv.jpg" alt=""
                                            class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/chess-class-sivakasi.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/pre-kg-block-yrtv.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery"><img
                                            src="https://www.yrtvschool.com/assets/images/gallery/sivakasi-library.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery">
                                        <img src="https://www.yrtvschool.com/assets/images/gallery/sivakasi-yrtv-school-dining-hall-students.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                    <a href="https://yrtvschool.dspeedup.in/pages/Gallery">
                                        <img src="https://www.yrtvschool.com/assets/images/gallery/sivakasi-yrtv-school-physics-lab.jpg"
                                            alt="" class="img-responsive">
                                    </a>
                                </div>
    
                                <a href="https://yrtvschool.dspeedup.in/pages/Gallery" fxLayoutAlign="start start" class="view-more">View more&nbsp;
                                    <mat-icon style="color: red;" class="thumb">chevron_right</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="100">
                        <div fxLayout="column" fxLayoutAlign="start start">
                            <h3 style="margin-bottom:20px ;">Principal's Message</h3>
                            <div fxLayout="row">
                                <div>
                                  <p><img src="https://www.yrtvschool.com/images/yrtv-principal.jpg" alt=""
                                    style="width:35%; height: 50%; float: left; margin-right: 50px;"> Dream as high as you can, as best dreams happen if you keep working at it. Make each
                                    day your master piece. Manifest plainness, embrace simplicity and give up selfishness.
                                    "He
                                    who sows virtue reaps great honour".<br>- Mr. L. MURALI
    
                                  </p>  
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
            <div class="bottonborder"fxLayout="row" fxLayoutAlign="space-between center">
                <div  fxLayout="row" fxLayoutAlign="start start">
                    <ul fxLayout="row" fxLayoutGap="10px"  style="font-size:8px ;">
                        <li><a href="">HOME</a></li>
                        <li><a href="https://yrtvschool.dspeedup.in/pages/YRTV">YRTV</a></li>
                        <li><a href="https://yrtvschool.dspeedup.in/pages/Gallery">GALLERY</a></li>
                        <li><a href="https://yrtvschool.dspeedup.in/pages/Achievements">ACHIEVEMENTS</a></li>
                        <li><a href="https://yrtvschool.dspeedup.in/contact-us">CONTACT</a></li>
                    </ul>
                </div>
                <div fxLayoutAlign="start end" style="font-size:14px ;">
                    <a href="" target="blank" style="color: #337ab7;text-decoration: none;">NWSIPL</a>
                </div>
            </div>
        </div>
    </div>
    
</div>