<div class="bg-container"    [style.background-image]="'url(' + serverPath + item.customField24 + ')'"  fxLayout="column" fxLayoutAlign="center center">
    <!-- Flex Container for Title and Search Bar -->

    <div fxLayout="column" fxLayoutAlign="center start" class="content-container">
        <!-- Title -->
        <h2 class="search-title" [innerHTML]="item.title" fxFlex.gt-sm="100%" fxFlex="auto"></h2>

        <!-- Search Bar Component -->
        <calsoft-search4 class="search-bar" fxFlex.gt-sm="80%" fxFlex="auto"></calsoft-search4>
    </div>
</div>
