
<div  *ngIf="type=='normal'; else fixed">


<div class="user-button-container" #userButton>

  <button mat-button fxHide.lt-md class="user-button" color="none"
   [matMenuTriggerFor]="accountMenu"
    #accountMenuTrigger="matMenuTrigger">
    <span fxLayout="row" fxLayoutAlign="start center">
      <span class="avatar-container" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [style.color]="data.headerIconColour" > account_circle</mat-icon>
        <i class="status "></i>
      </span>

      <div  *ngIf="displayNameFully">
        <span [style.color]="data.headerIconColour" 
         class="name" fxHide fxShow.gt-sm fxShow.gt-sm>Hi {{username}}</span>
      </div>

      <div *ngIf="!displayNameFully">
        <span [style.color]="data.headerIconColour" fxHide fxShow.gt-sm fxShow.gt-sm>&nbsp; ACCOUNT</span>
      </div>

      <mat-icon [style.color]="data.headerIconColour" class="icon" fxHide fxShow.gt-sm fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>
</div>

<mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="calsoft-dropdown ">
  <span (mouseleave)="accountMenuTrigger.closeMenu()">
    <a mat-menu-item routerLink="/account/information">
      <mat-icon class="material-icons-outlined mat-icon-sm">settings</mat-icon>
      <span>Account Settings</span>
    </a> 
    <a *ngIf="data?.disableYourOrders==0" mat-menu-item routerLink="/orders">
      <mat-icon class="material-icons-outlined mat-icon-sm">
        receipt
      </mat-icon>
      <span>Your Orders</span>
    </a>
    <a *ngIf="data?.disableMyAppointment==0" mat-menu-item routerLink="/myappoint">
      <mat-icon class="material-icons-outlined mat-icon-sm">
        calendar_today
      </mat-icon>
      <span>My AppointMents</span>
    </a>
    <a *ngIf="data?.enableMylearning==1" mat-menu-item routerLink="/myCourse">
      <mat-icon class="material-icons-outlined mat-icon-sm">
        school
      </mat-icon>
      <span>My Learning</span>
    </a>
    <a *ngIf="data?.disableWishlistHeader==0" mat-menu-item routerLink="/account/wishlist">
      <mat-icon class="material-icons-outlined mat-icon-sm">favorite</mat-icon>
      <span>Wishlist</span>
    </a>

    <div class="divider"></div>
    <a (click)="signOut()" mat-menu-item>
      <mat-icon class="material-icons-outlined mat-icon-sm">power_settings_new</mat-icon>
      <span>Sign Out</span>
    </a>
  </span>
</mat-menu> 
</div>

<ng-template #fixed>

<div  routerLink="/account/information" class="user-button-container" #userButton>
<button *ngIf="url!='/account/information'" mat-button fxHide.lt-md class="user-button">
<span fxLayout="row" fxLayoutAlign="start center">
  <span class="avatar-container" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon [style.color]="data.headerIconColour"> account_circle</mat-icon>
    <i class="status "></i>
  </span>
  <div *ngIf="displayNameFully">
    <span [style.color]="data.headerIconColour" class="name" fxHide fxShow.gt-sm fxShow.gt-sm>Hi {{username}}</span>
  </div>

  
  <div *ngIf="!displayNameFully">
    <span [style.color]="data.headerIconColour" class="name" fxHide fxShow.gt-sm fxShow.gt-sm>ACCOUNT</span>
  </div>
 
  <!-- <mat-icon class="icon" fxHide fxShow.gt-sm fxShow.gt-sm>keyboard_arrow_down</mat-icon> -->
</span>
</button>
</div>
</ng-template>