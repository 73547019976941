import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PayPalComponent } from './pay-pal/pay-pal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CcAvenueComponent } from './cc-avenue/cc-avenue.component';
import { RazorPayComponent } from './razor-pay/razor-pay.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { RedsysComponent } from './redsys/redsys.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PayUMoneyComponent } from './pay-umoney/pay-umoney.component';
import { OppwaComponent } from './oppwa/oppwa.component';
import { OwaStatusComponent } from './owa-status/owa-status.component';
import { PaytmComponent } from './paytm/paytm.component';
import { PaytmCheckoutComponent } from './paytm/components/paytm-checkout.component';
import { PhonepeComponent } from './phonepe/phonepe.component';
import { PaymentSuccessComponent } from './pay-umoney/payment-success/payment-success.component';
import { PayuComponent } from './payu/payu.component';
import { EasebuzzComponent } from './easebuzz/easebuzz.component';
import { StripeComponent } from './stripe/stripe.component';
import { PhonePeComponent } from './phone-pe/phone-pe.component';




@NgModule({
  declarations: [PayPalComponent, CcAvenueComponent, RazorPayComponent, 
    RedsysComponent,PayUMoneyComponent,
     OppwaComponent, OwaStatusComponent, PaytmComponent, PhonepeComponent, 
     PaymentSuccessComponent, PayuComponent,EasebuzzComponent, StripeComponent, PhonePeComponent],
  imports: [
    CommonModule,
    FormsModule,
    CustomFlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    
 
  ],
  exports: [
    PayPalComponent,
    RazorPayComponent,
    RedsysComponent,
    PayUMoneyComponent,
    OppwaComponent,
    PaytmComponent,
    CcAvenueComponent,
    PhonepeComponent,
    PayuComponent,
    EasebuzzComponent,
    StripeComponent,
    PhonePeComponent
  ]
})
export class PaymentGateWayModule { }
