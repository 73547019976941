
<mat-toolbar  class="primary" fxHide fxShow.lt-md>
    <mat-toolbar-row style="padding: 0px;" fxLayout="row" fxLayoutAlign="space-between center">
        <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

            <button style="color: #ffffff"*ngIf="url=='/home';else elseBlock" class="responsive-toggle"
             mat-icon-button
                (click)="toggleSidebar()">
                <i style="font-size: 18px;color:#fff" class="material-icons ">menu</i>
            </button>
            <ng-template #elseBlock>
                <button style="color: #ffffff" class="responsive-toggle " mat-icon-button (click)="back()">
                    <i class="material-icons ">keyboard_arrow_left</i>
                </button>
            </ng-template>
            <calsoft-logo [sitename]="data.siteName" fxFlex [type]="'light'"></calsoft-logo>
  
        </section>
        <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px">
            <div class="white" (click)="SignIn('login')" *ngIf="!userLoggedIn" fxLayout="row"
            fxLayoutAlign="center center">
            <button mat-button   [ngStyle]="{'padding':urlEnabled ? '1px' : '5px' }"  matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                Sign In
                <mat-icon>person</mat-icon>
                </button>
        </div>
        <div *ngIf="data.disableLoginHeader==0">
            <calsoft-HeaderUserProfileDropdown [data]="data" [color]="color"  class="headerUser" *ngIf="userLoggedIn">
            </calsoft-HeaderUserProfileDropdown>
            </div>
            <!-- <button *ngIf="(url!='/cart' && url!='/checkout'&& url!='/checkout/type')"  (click)="cart()" mat-button class="flex-row-button">
                <mat-icon>add_shopping_cart</mat-icon>
                <span *ngIf="userLoggedIn && cartServices.cartCount != 0" class="cart-items-count">
                    {{ cartServices.cartCount }}</span>
            </button> -->
            <calsoft-cart-icon [data]="data" ></calsoft-cart-icon>
  
        </section>
    </mat-toolbar-row>
    <mat-toolbar-row style="padding: 0px 10px 10px 10px ; height: 43px;">
        <calsoft-search [scroll]="false" ></calsoft-search>
    </mat-toolbar-row>
  
  </mat-toolbar>



   

  