import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constant } from '../constants/constant';

@Injectable({
  providedIn: 'root'
})
export class NewSubscriptionService {

  constructor(private http: HttpClient) { }

  url = environment.serverAPI4 + "/api/catalog/ruhisubscription";

  saveSubscriptionForm(object: any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}`, object);
  }

  url1 = environment.serverAPI4 + "/api/customformsubmission";

  url2 = environment.serverAPI4 + "/api/ig/customformsubmission";

  Customformsubmission(Customformsubmission: any): Observable<Response[]> {
    let authourized = this.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN']);
    if(guestLoginEnabled=="0"||authourized){
    return this.http.post<Response[]>(`${this.url1}`, Customformsubmission)
    }else{
      return this.http.post<Response[]>(`${this.url2}`, Customformsubmission)
    }
  }

  getAuthenticatedUser() {
    let userName = null;
    if (Constant.DEVICE_TYPE == "MOBILE") {
      userName = localStorage.getItem("authenticaterUser");
    } else {
      userName = this.getCookie("user-name");
    }
    return userName;
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

}
