<div *ngFor="let menu of parentMenu" class="menu-item">

    <a *ngIf="menu.subcategories.length==0" mat-button 
        fxLayout="row" fxLayoutAlign="space-between center"
         
       (click)="goToCategory(menu)" [id]="'menu-item-'+menu.categoryId">
        <span class="menu-title" style="font-family: var(--website-font-family);">{{ menu.categoryName  }}</span>
    </a>
    <a *ngIf="menu.subcategories.length>0" mat-button 
        fxLayout="row" fxLayoutAlign="space-between center"       
        (click)="onClick(menu.categoryId,menu.parentId,menu.subcategories)" [id]="'menu-item-'+menu.categoryId">
        <span class="menu-title font"  >{{ menu.categoryName  }}</span>
               <mat-icon *ngIf="isExpanded(menu.categoryId,menu.subcategories)" class="menu-expand-icon transition-2 menu-expand-icon1" >arrow_right_down</mat-icon>

        <mat-icon  *ngIf="!isExpanded(menu.categoryId,menu.subcategories)" class="menu-expand-icon transition-2" >arrow_right_down</mat-icon>
    </a>

    <div *ngIf="menu.subcategories.length>0" class="sub-menu" [id]="'sub-menu-'+menu.categoryId" [style.marginLeft.px]="depth < 2 ? 15 : 0" >
        
        <calsoft-sidebar-menu-new  [menuItems]="menu.subcategories" [menuParentId]="menu.categoryId"  [depth]="depth + 1"></calsoft-sidebar-menu-new>
    </div>
</div>