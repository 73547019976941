<div *ngIf="reInit">

    <mat-form-field [fxFlex]="field.customfield4 === '2' ? 50 : 100 " appearance="outline">
        <mat-label>
            {{field.label}} <span *ngIf="isRequired(field.name)" class="required-symbol">*</span>
        </mat-label>

        <mat-select (selectionChange)="setValue($event.value)" [placeholder]="field.label"
            [formControl]="localFormControl">
            <mat-option *ngFor="let item of optionsList" [value]="item">{{item}}</mat-option>
        </mat-select>
        <mat-hint *ngFor="let validation of field['dynamicFormLabelValidator'];">{{validation.hintMessage}}</mat-hint>
    </mat-form-field>

    <div *ngIf="field.customfield4 === '2'">
        <div *ngIf="selectedValue">
            <div class="padding-10" fxLayout="column">
                <div fxLayout="row">
                    <p>{{field.label}}: {{selectedCustomFieldValue}}</p>
                </div>
            </div>
        </div>
    </div>

</div>



