<div *ngIf="display">
    <mat-toolbar style="z-index: 1000;" fxHide fxShow.gt-md fxShow.md  class="primary" fxLayout="row" style="padding-top: 15px;"
      >
        <mat-toolbar-row class="theme-container" style="height: 100%;"  fxLayoutAlign="space-between center">

        <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
            fxFlex.gt-sm="300px">
            <calsoft-search3></calsoft-search3>
        </div>
        <!-- <section>
            <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
        </section> -->
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
            <div *ngIf="data.disableLoginHeader==0">
            <button style="color:#ffffff" *ngIf="!userLoggedIn" (click)="SignIn('login')" mat-button>
                Login/Signup
            </button>
            </div>
            <div *ngIf="data.disableLoginHeader==0">
            <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
            </calsoft-HeaderUserProfileDropdown>
            </div>
            <calsoft-cart-icon [data]="data" ></calsoft-cart-icon>
        </div>
        </mat-toolbar-row>
    </mat-toolbar>
    

    <mat-toolbar style="z-index: 1000;" fxHide fxShow.gt-md fxShow.md  class="calsoft-fixed-header primary" fxLayout="row"
      >
        <mat-toolbar-row class="theme-container" style="height: 100%;"  fxLayoutAlign="space-between center">
        <section>
            <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
        </section>
        <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
            fxFlex.gt-sm="500px">
            <calsoft-search></calsoft-search>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
            <button style="color:#ffffff" *ngIf="!userLoggedIn" (click)="SignIn('login')" mat-button>
                Login/Signup
            </button>
            <div *ngIf="data.disableLoginHeader==0">
            <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
            </calsoft-HeaderUserProfileDropdown>
            </div>
            <calsoft-cart-icon [data]="data"  ></calsoft-cart-icon>
        </div>
        </mat-toolbar-row>
    </mat-toolbar>
    </div>
    <div *ngIf="!display">

        <div *ngIf="mobileHeaderType==1">
            <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
            </calsoft-mobile-header>
        </div>
    
        <div *ngIf="mobileHeaderType==0">
            <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
            </calsoft-mobile-header-new>
        </div>
    
        <div *ngIf="mobileHeaderType==2">
            <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
            </calsoft-mobile-header3>
        </div>
    
        <div *ngIf="mobileHeaderType==3">
            <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
            </calsoft-mobile-header4>
        </div>
    
        <div *ngIf="mobileHeaderType==4">
            <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
            </calsoft-mobile-header5>
        </div>
    
        <div *ngIf="mobileHeaderType==5">
            <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
            </calsoft-mobile-header6>
        </div>
        <div *ngIf="mobileHeaderType==6">
            <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        
            </calsoft-mobile-header7>
        </div>
        <div *ngIf="mobileHeaderType==7">
        
            <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        
            </calsoft-mobile-header8>
        </div>
    
    </div>
    
    
    <!-- Mobile View  end -->
    