<!-- footer.component.html -->

  
    
<!-- footer.component.html -->
<footer>
    <div fxHide.xs>
    <div class="content" fxLayout="column" fxLayoutAlign="start" style="background: #411f9a;">


        <div class="top" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="logo-details">
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
            </div>
            <div class="media-icons" fxFlex="none">
                <a *ngFor="let item of footerIconData" [href]="item.link">
                    <i class="fab" [ngClass]="'fa-' + item.iconName.toLowerCase()"></i>
                </a>
            </div>

        </div>
        <hr>
        <div class="link-boxes" fxLayout="row wrap" fxLayoutAlign="space-between start">
            <ul class="box" fxFlex="16">
                <li class="link_name">Discover</li>
                <li style="cursor: pointer;"><a routerLink="/aboutus">About Us</a></li>
                <li style="cursor: pointer;"><a routerLink="/contact-us">Contact us</a></li>
               
            </ul>
            <ul class="box" fxFlex="16">
                <li class="link_name">Policy</li>
                <li style="cursor: pointer;"><a routerLink="/returnPolicy" >{{data.refundPolicyName}}</a></li>
                <li style="cursor: pointer;"><a routerLink="/terms&conditions" >Terms & Conditions</a></li>
                <li style="cursor: pointer;" ><a routerLink="/privacy" >Privacy Policy</a></li>
                <li style="cursor: pointer;" ><a routerLink="/shippingPolicy" >Shipping Policy</a></li>
                <li style="cursor: pointer;" ><a routerLink="/paymentPolicy" >Payment Policy</a></li>
            </ul>
            <ul class="box" fxFlex="16">
                <li  class="link_name">Contact Information</li>
                <li style="cursor: pointer;"><a  (click)="callPhone()" >{{ data.storePhoneNumber }}</a></li>
                <li style="cursor: pointer;"  ><a (click)="composeEmail(data.smtpUsername)">{{data.smtpUsername}}</a></li>
    
                <li style="cursor: pointer;" ><a  (click)="getDirections(data.googleMapNavigation)">{{data.streetAddress}}</a></li>
                <li style="cursor: pointer;" ><a *ngIf="data.googleMapEnable==0">{{data.streetAddress}}</a></li>
            </ul>
            <ul class="box" fxFlex="16">
                

                <li class="link_name">Categories</li>
                <div class="subcategory-list">
                    <div *ngFor="let subcategory of categoryArray | slice:0:5" style="margin-bottom: 10px;">
                        <a (click)="goToCategory(subcategory)" class="sub-cat"        style="display: flex; cursor: pointer; align-items: center; color: #fff; text-transform: uppercase;opacity: 0.8;">
                            {{ subcategory.categoryName }}
                        </a>
                    </div>
                </div>
  
  
                
            </ul>
            <ul class="box input-box" fxFlex="16">
                <li class="link_name">Subscribe</li>
                <li>
                    <form [formGroup]="firstFormGroup" class="subscribe-search-form" fxLayout="column" fxFlex="100">
                        <input type="text" class="text" formControlName="subscriberEmail"
                            placeholder="Enter your email.." style="letter-spacing: 1px;padding: 12px" fxFlex />
                        <mat-error *ngIf="firstFormGroup.get('subscriberEmail').hasError('required')">
                            Email is required
                        </mat-error>
                        <mat-error *ngIf="firstFormGroup.get('subscriberEmail').hasError('pattern')">
                            Enter a valid email address
                        </mat-error>
                        <button [disabled]="firstFormGroup.invalid" (click)="subscribe1()" type="button"
                            class="subscribe-button" style="background-color: white; color: rgb(56, 36, 210); padding: 12px; margin-top: 10px;font-size: 19px;
  font-weight: 400;">
                            &nbsp;Subscribe
                        </button>
                    </form>
                </li>
            </ul>


        </div>

    </div>
    <div class="bottom-details" fxLayout="row" style="background-color:  rgb(4, 154, 200);padding: 20px;">
        <div fxFlex="10"></div>
        <div class="bottom_text" fxFlex="30">
            <span class="copyright_text">Copyright © <span class="footer-undername"
                    style="cursor: pointer;">{{data.storeName}} </span> {{currentYear}}. All rights reserved.</span>

        </div>
        <div fxFlex="20">

        </div>


        <div fxFlex="30">
            <span class="policy_terms">
                <a routerLink="/privacy">Privacy Policy</a>
                <a routerLink="/terms&conditions">Terms & Conditions</a>
            </span>
        </div>
        <div fxFlex="10">

        </div>

    </div>
    </div>
    <div class="mobile-only">  
        <div class="content" fxLayout="column" fxLayoutAlign="start" style="background: #411f9a;">
    
            <div class="top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="start center">
                <div class="logo-details" fxHide.xs>
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                </div>
                <div class="media-icons" fxFlex="none">
                    <a *ngFor="let item of footerIconData" [href]="item.link">
                        <i class="fab" [ngClass]="'fa-' + item.iconName.toLowerCase()"></i>
                    </a>
                </div>
            </div>
            <hr>
            <div class="link-boxes" fxLayout="column" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutAlign.xs="start stretch">
                <!-- Row 1: Discover and Policy -->
                <div fxLayout="row wrap" fxLayoutAlign="start start" style="width: 100%;">
                    <ul class="box" fxFlex="16" fxFlex.xs="33.33" style="padding: 0 8px;">
                        <li class="link_name">Discover</li>
                        <li style="cursor: pointer;"><a routerLink="/aboutus">About Us</a></li>
                        <li style="cursor: pointer;"><a routerLink="/contact-us">Contact us</a></li>
                    </ul>
                    <ul class="box" fxFlex="16" fxFlex.xs="33.33" style="padding: 0 8px;">
                        <li class="link_name">Policy</li>
                        <li style="cursor: pointer;"><a routerLink="/returnPolicy">{{data.refundPolicyName}}</a></li>
                        <li style="cursor: pointer;"><a routerLink="/terms&conditions">Terms & Conditions</a></li>
                        <li style="cursor: pointer;"><a routerLink="/privacy">Privacy Policy</a></li>
                        <li style="cursor: pointer;"><a routerLink="/shippingPolicy">Shipping Policy</a></li>
                        <li style="cursor: pointer;"><a routerLink="/paymentPolicy">Payment Policy</a></li>
                    </ul>
                    <ul class="box" fxFlex="16" fxFlex.xs="33.33" style="padding: 0 8px;">
                        <li class="link_name">Categories</li>
                        <div class="subcategory-list">
                            <div *ngFor="let subcategory of categoryArray | slice:0:5" style="margin-bottom: 10px;">
                                <a (click)="goToCategory(subcategory)" class="sub-cat" style="display: flex; cursor: pointer; align-items: center; color: #fff; text-transform: uppercase; opacity: 0.8;">
                                    {{ subcategory.categoryName }}
                                </a>
                            </div>
                        </div>
                    </ul>
                </div>
                <!-- Row 2: Contact Information (full width) -->
                <div style="width: 100%; padding: 0 8px;">
                    <ul class="box" fxFlex="100" style="padding: 0;">
                        <li class="link_name">Contact Information</li>
                        <li style="cursor: pointer;"><a (click)="callPhone()">{{ data.storePhoneNumber }}</a></li>
                        <li style="cursor: pointer;"><a (click)="composeEmail(data.smtpUsername)">{{data.smtpUsername}}</a></li>
                        <li style="cursor: pointer;"><a (click)="getDirections(data.googleMapNavigation)">{{data.streetAddress}}</a></li>
                        <li style="cursor: pointer;" *ngIf="data.googleMapEnable==0">{{data.streetAddress}}</li>
                    </ul>
                </div>
                <!-- Row 3: Categories and Subscribe -->
                <div fxLayout="row wrap" fxLayoutAlign="start start" style="width: 100%;">
                    
                    <ul class="box input-box" fxFlex="50" fxFlex.xs="100" style="padding: 0 8px;">
                        <li class="link_name">Subscribe</li>
                        <li>
                            <form [formGroup]="firstFormGroup" class="subscribe-search-form" fxLayout="column" fxFlex="100">
                                <input type="text" class="text" formControlName="subscriberEmail"
                                    placeholder="Enter your email.." style="letter-spacing: 1px; padding: 12px" fxFlex />
                                <mat-error *ngIf="firstFormGroup.get('subscriberEmail').hasError('required')">
                                    Email is required
                                </mat-error>
                                <mat-error *ngIf="firstFormGroup.get('subscriberEmail').hasError('pattern')">
                                    Enter a valid email address
                                </mat-error>
                                <button [disabled]="firstFormGroup.invalid" (click)="subscribe1()" type="button"
                                    class="subscribe-button" style="background-color: white; color: rgb(56, 36, 210); padding: 12px; margin-top: 10px; font-size: 19px; font-weight: 400;">
                                    &nbsp;Subscribe
                                </button>
                            </form>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
        
        
        <div class="bottom-details" fxLayout="row" fxLayout.xs="column" style="background-color: rgb(4, 154, 200); padding: 20px;">
            <div fxFlex="10" fxFlex.xs="10"></div>
            <div class="bottom_text" fxFlex="30" fxFlex.xs="100">
                <span class="copyright_text">Copyright © 
                    <span class="footer-undername" style="cursor: pointer;">{{data.storeName}}</span> 
                    {{currentYear}}. All rights reserved.
                </span>
            </div>
            <div fxFlex="20" fxFlex.xs="20"></div>
            <div fxFlex="30" fxFlex.xs="30">
                <span class="policy_terms">
                    <a routerLink="/privacy">Privacy Policy</a>
                    <a routerLink="/terms&conditions">Terms & Conditions</a>
                </span>
            </div>
            <div fxFlex="10" fxFlex.xs="10"></div>
        </div>
    </div>
</footer>