import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DateComponent } from './date/date.component';
import { DynamicFieldDirective } from './dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { InputComponent } from './input/input.component';
import { RadiobuttonComponent } from './radiobutton/radiobutton.component';
import { SelectComponent } from './select/select.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { PriceComponent } from './price/price.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DirectiveModule } from '../utils/directive/directive.module';
import { CommonFileUploadModule } from '../common-file-upload/common-file-upload.module';
import { PasswordConfirmComponent } from './password-confirm/password-confirm.component';
import { PasswordComponent } from './password/password.component';
import { ImageComponent } from './image/image.component';
import { SubFormComponent } from './sub-form/sub-form.component';
import { SubscriptionPlanModule } from '../subscription-plan/subscription-plan.module';
import { PaymentGateWayModule } from 'src/app/pages/payment-gate-way/payment-gate-way.module';

@NgModule({
  declarations: [
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    PriceComponent,
    FileUploadComponent,
    PasswordConfirmComponent,
    PasswordComponent,
    ImageComponent,
    SubFormComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    DirectiveModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    CustomFlexLayoutModule,
    CommonFileUploadModule,
    SubscriptionPlanModule,
    PaymentGateWayModule

  ],
  exports: [
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    DynamicFormComponent,
    ImageComponent,
    SubFormComponent
  ]
})
export class DynamicComponentModule { }
