import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-search-with-bg',
  templateUrl: './search-with-bg.component.html',
  styleUrls: ['./search-with-bg.component.scss']
})
export class SearchWithBgComponent {

  @Input() img: any;
 public serverPath = environment.commonImageApi + '/home/'
  @Input() item: any;

}
