<mat-toolbar class="mobileheader">

        <mat-toolbar-row style="padding: 0px;" fxLayout="row" [style.margin]="mobileHeaderLogoGap"
            [style.height]="mobileHeaderHeight">

            <div fxFlex="10%">
                <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock"
                 class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
                    <i style="font-size: 18px" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
                </button>
            <ng-template #elseBlock>
                <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button
                    (click)="back()">
                    <i class="material-icons ">keyboard_arrow_left</i>
                </button>
            </ng-template>
            </div>


            <div fxFlex="60%">
                <section *ngIf="layout==1&&mobileLogoEnable==0" fxLayout="row" fxLayoutAlign="center center">

                    <calsoft-logo [sitename]="data.siteName" *ngIf="!displaySearchBar" fxFlex fxLayoutAlign="center center"
                        [type]="'light'"></calsoft-logo>
    
                </section>
    
                <div *ngIf="layout==1&&mobileLogoEnable==1" fxLayoutAlign="center center">
                    <img *ngIf="!displaySearchBar" style="width: 100%;" [src]="serverPath3+mobileLogoSrc" fxLayoutAlign="center center"
                        onerror="this.src='';" />
                </div>
    
                <mat-divider *ngIf="url == '/cart'"></mat-divider>
            </div>
           

            <div fxFlex="30%">
                <section *ngIf="layout==1" fxLayout="column" fxLayoutAlign="end center" >
                    <div style="margin-left: 30px;">
                        <div fxLayoutAlign="end end" *ngIf="data.disableSearchInHeader==0">
        
                            <div *ngIf="url != '/cart'&& url !='/checkout'">
                                <mat-icon [style.color]="data.headerIconColour" style=" cursor: pointer;" fxLayoutAlign="end end"
                                    *ngIf="!displaySearchBar" (click)="showSearchBar()">search</mat-icon>
                            </div>
        
                            <div *ngIf="displaySearchBar" class="search-bar" fxLayout="row" fxLayoutAlign="space-between center">
                                <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                                    [searchbarborderRadius]="'15px'"></calsoft-search>
                            </div>
        
                        </div>
                        <calsoft-cart-icon fxLayoutAlign="end end" *ngIf="disableCartIconInHeader==0" fxFlex
                        [data]="data"  [style.color]="data.headerIconColour">
                        </calsoft-cart-icon>
                    </div>
                
                    <div *ngIf="disableLoginHeader==0">
                        <div class="black" (click)="SignIn('login')" *ngIf="!userLoggedIn" fxLayout="row"
                            fxLayoutAlign="end end">
                            <button mat-button *ngIf="!displaySearchBar" matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [style.color]="data.headerIconColour"
                                [attr.aria-pressed]="hide">
                                Sign In
                                <mat-icon>person</mat-icon>
                            </button>
                        </div>
                    </div>
                </section>
            </div>

        </mat-toolbar-row>





</mat-toolbar>




<mat-toolbar style="padding:0px ; height: 43px;" fxHide fxShow.lt-md class="calsoft-fixed-header primary" fxLayout="row"
    fxLayoutAlign="space-between center">
    <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

        <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock" class="responsive-toggle"
            mat-icon-button (click)="toggleSidebar()">
            <i style="font-size: 18px" class="material-icons ">menu</i>
        </button>
        <ng-template #elseBlock>
            <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button (click)="back()">
                <i class="material-icons ">keyboard_arrow_left</i>
            </button>
        </ng-template>
    </section>
    <section fxFlex *ngIf="data.disableSearchInHeader==0">
        <calsoft-search></calsoft-search>
    </section>
    <section>
        <calsoft-cart-icon *ngIf="disableCartIconInHeader==0" [data]="data" [style.color]="data.headerIconColour"></calsoft-cart-icon>
    </section>
</mat-toolbar>
<!-- Mobile View  end -->