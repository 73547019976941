import {Directive, ElementRef, Injectable} from '@angular/core';
import { BaseDirective2 } from './base-directive2';
import { MediaMarshaller } from './media-marshaller';
import { StyleBuilder } from './style-builder';
import { StyleDefinition, StyleUtils } from './style-utils';


@Injectable({providedIn: 'root'})

export class FlexAlign extends StyleBuilder {
    buildStyles(input: string) {
      input = input || 'stretch';
      const styles: StyleDefinition = {};
  
      // Cross-axis
      switch (input) {
        case 'start':
          styles['align-self'] = 'flex-start';
          break;
        case 'end':
          styles['align-self'] = 'flex-end';
          break;
        default:
          styles['align-self'] = input;
          break;
      }
  
      return styles;
    }
  }
  
  const inputs = [
    'fxFlexAlign', 'fxFlexAlign.xs', 'fxFlexAlign.sm', 'fxFlexAlign.md',
    'fxFlexAlign.lg', 'fxFlexAlign.xl', 'fxFlexAlign.lt-sm', 'fxFlexAlign.lt-md',
    'fxFlexAlign.lt-lg', 'fxFlexAlign.lt-xl', 'fxFlexAlign.gt-xs', 'fxFlexAlign.gt-sm',
    'fxFlexAlign.gt-md', 'fxFlexAlign.gt-lg'
  ];
  const selector = `
    [fxFlexAlign], [fxFlexAlign.xs], [fxFlexAlign.sm], [fxFlexAlign.md],
    [fxFlexAlign.lg], [fxFlexAlign.xl], [fxFlexAlign.lt-sm], [fxFlexAlign.lt-md],
    [fxFlexAlign.lt-lg], [fxFlexAlign.lt-xl], [fxFlexAlign.gt-xs], [fxFlexAlign.gt-sm],
    [fxFlexAlign.gt-md], [fxFlexAlign.gt-lg]
  `;
  
  /**
   * 'flex-align' flexbox styling directive
   * Allows element-specific overrides for cross-axis alignments in a layout container
   * @see https://css-tricks.com/almanac/properties/a/align-self/
   */
  @Directive()
  export class FlexAlignDirective extends BaseDirective2 {
  
    protected override DIRECTIVE_KEY = 'flex-align';
  
    constructor(elRef: ElementRef,
                styleUtils: StyleUtils,
                styleBuilder: FlexAlign,
                marshal: MediaMarshaller) {
      super(elRef, styleBuilder, styleUtils, marshal);
      
    }
  
    protected styleCache = flexAlignCache;
  }
  
  const flexAlignCache: Map<string, StyleDefinition> = new Map();
  
  @Directive({selector, inputs})
  export class DefaultFlexAlignDirective extends FlexAlignDirective {
    protected inputs = inputs;
  }