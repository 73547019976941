<div *ngIf="display">
<mat-toolbar style="border-bottom: 1px solid #cbc0c0;" fxHide  fxShow.gt-md
    class=" app-toolbar bg" fxLayoutAlign="space-between center">
    <mat-toolbar-row   class="theme-container" fxLayoutAlign="space-between center" >

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <section>
                <div  fxFlex="70" fxLayoutAlign="space-around center" class="padding-5">
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                </div>
            </section>
        </div>

        <div fxLayout="row" flex="50">

            <calsoft-Menu fxLayoutAlign="start center" [data]="data" [type]="'one'" style="z-index: 1000;"
                [categoryArray]="item"></calsoft-Menu>
        </div>

        <div fxLayout="row">

            <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">

                <div *ngIf="data.disableSearchInHeader==0">

                <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                    <mat-icon style=" cursor: pointer;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                        (click)="showSearchBar()">search</mat-icon>
                </div>


                <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                    fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
                    <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                        [searchbarborderRadius]="'15px'"></calsoft-search>
                </div>

                </div>

            </div>

            <div *ngIf="data.displayPincode==1" class="search-bar padding-10" fxLayoutGap="10px" flex
                fxLayoutAlign="center center" fxFlex>
                <button mat-flat-button (click)="availablePostalCode()">Pincode
                    <mat-icon> location_on</mat-icon>

                </button>

            </div>

            <div class="cart" fxLayoutAlign="end center" *ngIf="url != '/cart'">
                <calsoft-cart-icon [data]="data"   ></calsoft-cart-icon>
            </div>
            <div *ngIf="data.disableLoginHeader==0">
            <div *ngIf="!userLoggedIn" (click)="SignIn('login')" fxLayoutAlign="end center">
                <button mat-flat-button style="color: black" *ngIf="!userLoggedIn">
                    <mat-icon>person</mat-icon>

                </button>
            </div>
            </div>
            <div *ngIf="data.disableLoginHeader==0">
            <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center" 
                *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                </div>

        </div>
    </mat-toolbar-row>

</mat-toolbar>
<mat-toolbar fxHide fxShow.gt-md>
    <br>
    <br>
</mat-toolbar>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>

</div>

<!-- <calsoft-mobile-header-fixed [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

</calsoft-mobile-header-fixed> -->
<!-- Mobile View  end -->