<div *ngIf="display">
    <header class="header header-6 theme-container" fxHide fxShow.gt-md>

        <!-- <div class="header-top">
            <div class="containerCss">
                <div class="container">
                    
                    <div class="header-left">
                        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                    </div>

                    <div class="header-center"  fxLayout="row"  style="width: 100%; height: 70px;" >
                       <div fxFlex="10"></div>
                       <div fxFlex  fxLayout="row" fxLayoutAlign="center center"   style="width: 100%; height:100%;">
                            <div fxFlex="25" fxLayout="row" fxLayoutGap="10px" class="border">
                                <div >
                                    <mat-icon>card_membership</mat-icon>
                                </div>
                                <div>
                                    <h3>Become a Member</h3>
                                    <p>Additional 10% off on stays</p>
                                </div>
                            </div>
                            <div fxFlex="25" fxLayout="row"  fxLayoutGap="10px" class="border">
                                <div>
                                    <mat-icon>card_membership</mat-icon>
                                </div>
                                <div>
                                    <h3>Become a Member</h3>
                                    <p>Additional 10% off on stays</p>
                                </div>
                            </div>
                            <div fxFlex="25" fxLayout="row"  fxLayoutGap="10px" class="border">
                                <div>
                                    <mat-icon>card_membership</mat-icon>
                                </div>
                                <div>
                                    <h3>Become a Member</h3>
                                    <p>Additional 10% off on stays</p>
                                </div>
                            </div>
                            <div fxFlex="25" fxLayout="row"  fxLayoutGap="10px" class="border">
                                <div>
                                    <mat-icon>card_membership</mat-icon>
                                </div>
                                <div>
                                    <h3>Become a Member</h3>
                                    <p>Additional 10% off on stays</p>
                                </div>
                            </div>
                       </div>
                        <div fxFlex="10">

                       </div>
                    </div>

                    <div class="header-rigth" style="cursor:pointer ;font-size: 12px;" fxLayoutAlign="center center">
                        <div class="login padding-10" style="font-size:12px" *ngIf="languageSelection==1">
                            <calsoft-language></calsoft-language>
                        </div>

                        <div *ngIf="data.disableLoginHeader==0">
                            <div class="login padding-10" *ngIf="!userLoggedIn" (click)="SignIn('login')">Login</div>
                        </div>

                        <calsoft-HeaderUserProfileDropdown [data]="data"
                            *ngIf="userLoggedIn">
                        </calsoft-HeaderUserProfileDropdown>
                    </div>
                    
                </div>
            </div>
        </div> -->
        <div class="container" fxLayout="row" >
            <div fxFlex="20">
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </div>
            <div fxLayout="row" style="width: 100%;">
                <div fxFlex="5"></div>
                <div fxFlex fxLayout="row" >
                    <div fxFlex="25" class="rigth" fxLayoutAlign="center center" >
                        <a href=""  class="padding-10">
                            <div fxLayout="row" fxLayoutGap="10px"  >
                                <div fxLayoutAlign="center center">
                                    <mat-icon>card_membership</mat-icon>
                                </div>
                                <div>
                                    <h3>Become a Member</h3>
                                    <p>Additional 10% off on stays</p>
                                </div>
                            </div>
                           
                        </a>
                       
                    </div>
                    <div fxFlex="25" class="rigth" fxLayoutAlign="center center" >
                        <a href="" class="padding-10">
                            <div fxLayout="row" fxLayoutGap="10px" >
                                <div fxLayoutAlign="center center">
                                    <mat-icon>card_membership</mat-icon>
                                </div>
                                <div>
                                    <h3>Become a Member</h3>
                                    <p>Additional 10% off on stays</p>
                                </div>
                            </div>
                           
                        </a>
                       
                    </div>
                    <div fxFlex="25" class="rigth" fxLayoutAlign="center center" >
                        <a href=""  class="padding-10">
                            <div fxLayout="row" fxLayoutGap="10px" >
                                <div fxLayoutAlign="center center">
                                    <mat-icon>card_membership</mat-icon>
                                </div>
                                <div>
                                    <h3>Become a Member</h3>
                                    <p>Additional 10% off on stays</p>
                                </div>
                            </div>
                           
                        </a>
                       
                    </div>
                    <div fxFlex="25" class="rigth"  fxLayoutAlign="center center" >
                        <a href=""  class="padding-10">
                            <div fxLayout="row" fxLayoutGap="10px" >
                                <div fxLayoutAlign="center center">
                                    <mat-icon>card_membership</mat-icon>
                                </div>
                                <div>
                                    <h3>Become a Member</h3>
                                    <p>Additional 10% off on stays</p>
                                </div>
                            </div>
                           
                        </a>
                       
                    </div>
                </div>
                
            </div>
            <div fxFlex="10" style="cursor:pointer ;font-size: 20px;" fxLayoutAlign="center center">
                <div class="login padding-10" style="font-size:20px" *ngIf="languageSelection==1">
                    <calsoft-language></calsoft-language>
                </div>

                <div *ngIf="data.disableLoginHeader==0">
                    <div class="login padding-10" style="font-size:20px" *ngIf="!userLoggedIn" (click)="SignIn('login')">Login</div>
                </div>
                <div *ngIf="data.disableLoginHeader==0">

                <calsoft-HeaderUserProfileDropdown [data]="data"
                    *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>
                </div>
            </div>

        </div>

        <div fxLayoutAlign="center center" class="container">

            <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="item"></calsoft-Menu>
        </div>


        <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
        fxLayoutAlign="space-between center" style="z-index: 1000;">
        <mat-toolbar-row class=" theme-container" style="height: 100%;" fxLayoutAlign="space-between center">
            <section class="padding-10" style="padding-left: 7%;">
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </section>
            <section *ngIf="data.disableSearchInHeader==0">
                <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center"
                    fxFlex fxFlex.gt-sm="500px">
                    <calsoft-search [searchbarborderRadius]="'15px'"></calsoft-search>
                </div>
            </section>
            <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">

                <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
                    <div style="color: #ffffff" fxLayout="column">
                        <small>Hello, Sign in</small>
                        <span>Account & Lists</span>
                    </div>
                </button>
                <div *ngIf="data.disableLoginHeader==0">
                <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser"
                    *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>
                </div>

                <calsoft-cart-icon [data]="data" ></calsoft-cart-icon>
            </section>
        </mat-toolbar-row>

    </mat-toolbar>

    </header>
</div>


<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>

</div>