import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfig } from "../field.interface";

@Component({
  selector: "calsoft-input",
  templateUrl: "./input.component.html",
    styles: []
})
export class InputComponent implements OnInit {
  @Input() field: FieldConfig;
  @Input() customField13: any = 0;
  @Input() group: UntypedFormGroup;
  @Input() type: any;
  @Output() valueChange = new EventEmitter<any>();
  inputLength: number = 0;
  display: boolean = false;
  resultvalue: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field && changes.field.currentValue) {
      const control = this.group.get(this.field.name);
      if (control) {
        control.valueChanges.subscribe(value => {
          this.inputLength = value?.length;
        });
      }
    }
  }

 

  ngOnInit() {
  
    
    const control = this.group.get(this.field.name);
    if (control) {
      control.valueChanges.subscribe(value => {
        this.inputLength = value?.length;
     //   this.checkCondition();
      });
    }

    if (this.group != undefined) {
      this.display = true;
    }
  }




  isRequired(fieldName: string): boolean {
    const control = this.group.get(fieldName);
    return control && control.hasError('required');
  }



  setValue(value: any) {
    if (this.group.get(this.field.name)?.valid) {
      this.group.get(this.field.name).setValue(value);
      this.valueChange.emit( this.field.name);
    } else {
      console.error('Form control is invalid');
    }
  }
}
