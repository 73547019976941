import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from '../components/logo/logo.component';
import { BreadcrumbComponent } from './breadcrumb.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { CertificateDownloadComponent } from 'src/app/pages/order/certificate-download/certificate-download.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatIconModule,
    CustomFlexLayoutModule,
  ],exports:[
    RouterModule,
    BreadcrumbComponent,
  ]
})
export class BreadcrumbModule { }
