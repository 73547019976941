import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../field.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnChanges {
  @Input() field: FieldConfig;
  @Input() group: UntypedFormGroup;
  @Input() index: any;
  @Input() indexInital: any;
  display: boolean = false;
  initForm: boolean = true;
  @Input() indexInitial:any = 0;
  @Output() valueChange = new EventEmitter<any>();
  reInit: boolean = false;
  constructor() { }

  public serverPath = environment.commonImageApi + '/formImage/';
  imageValues: any = [];
  filteredImages: any = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field.currentValue && changes.field.currentValue.customfield2) {
      this.imageValues = JSON.parse(this.field.customfield2);
      this.filteredImages = this.imageValues;
    }
  }

  ngOnInit() {
    if (this.indexInitial > this.index) {
      // Your existing logic
    }

    if (this.group != undefined) {
      this.display = true;
    }

    if (this.field && this.field.customfield2) {
      this.imageValues = JSON.parse(this.field.customfield2);
      this.filteredImages = this.imageValues;
    }

    this.reInit = true;
  }

  isRequired(fieldName: string): boolean {
    const control = this.group.get(fieldName);
    return control && control.hasError('required');
  }

  setValue(value: string, searchInput: HTMLInputElement) {
    this.group.get(this.field.name).setValue(value);
    this.indexInitial = this.index + 1;
    this.valueChange.emit(this.field.name);
  
    // Clear the search input and reset the filtered images
    searchInput.value = '';
    this.filteredImages = this.imageValues;
  }

  onSearch(query: string) {
    this.filteredImages = this.imageValues.filter(image =>
      image.value.toLowerCase().includes(query.toLowerCase())
    );
  }
}
