import { Component, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-menu-iteration3',
  templateUrl: './menu-iteration3.component.html',
  styleUrls: ['./menu-iteration3.component.scss']
})
export class MenuIteration3Component implements OnInit{

  @Input() categoryArray: any;
  @Input() menutype : any;
  @Input() data: any;
  highlightedindex: any=0;

  constructor(
    public router: Router,
    private commonService: CommonService,
    private ren: Renderer2

    ) {
  }
  ngOnInit() {
    this.subMenuOpen = new Array(this.categoryArray.length).fill(false);
    let value = localStorage.getItem("menuhighlighted");

    if(value != undefined && value != null){
      this.highlightedindex = value ; 
    }
  }


  goToCategory(menu,i) {

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

    this.highlightedindex = i ;
    

    localStorage.setItem("menuhighlighted",this.highlightedindex); 

  }
  menuOpen = false;
  subMenuOpen: boolean[] = [];

  selectedCategory: number | null = null;
  selectedChild: any = null;

 

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  toggleSubMenu(index: number) {
    this.subMenuOpen[index] = !this.subMenuOpen[index];
  }
  @HostListener('window:click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    const menuElement = document.querySelector('.navigation');
    const menuIconElement = document.querySelector('.menu-icon');
    if (this.menuOpen && menuElement && !menuElement.contains(target) && !menuIconElement?.contains(target)) {
       // Close the menu if clicked outside
       this.toggleMenu();
    }
  }
 
  toggleMenuVisibility() {
    this.menuOpen = !this.menuOpen; // Toggle the menu visibility
  }



  

}
