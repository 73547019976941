<ng-container *ngIf="searchDisabled">
    <div class="search-wrapper" fxLayout="row" 
         [ngClass]="{ 'focus': focused, 'has-input': input }" 
         fxFlex (vrClickOutside)="closeDropdown()">
        <!-- Search input container -->
        <div class="search-input-wrapper">
            <input type="search" [ngStyle]="{'border-radius':searchbarborderRadius }"
                   class="search-input"
                   [(ngModel)]="search"
                   (input)="filterOption($event)" 
                   (keyup.enter)="onKeydown($event)" 
                   (focus)="openDropdown()"
                   [attr.placeholder]="placeholderText" #inputElem 
                   [matAutocomplete]="auto"/>
        </div>
        <!-- Search Icon -->
        <div class="search-icon-wrapper" (click)="hideSearchBar()">
            <mat-icon class="search-icon">search</mat-icon>
        </div>

        <!-- Autocomplete -->
        <mat-autocomplete class="mat-elevation-z2 autocmp" #auto="matAutocomplete">
            <mat-option *ngFor="let filter of filteredOptions" 
                        (keydown.enter)="getProducts(filter.name)" 
                        (click)="getProducts(filter.name)" 
                        [value]="filter.name">
                <mat-icon>search</mat-icon>
                <span>{{ filter.name }}</span>
            </mat-option>
        </mat-autocomplete>
    </div>
</ng-container>
