<div *ngIf="display">
    <div class="header">
        <div class="container">
            <div class="group-title-index" id="style-GBRGa">
                <div class="row snipcss0-3-3-4" fxLayout="row">
                    <div (click)="logo()" style="cursor: pointer;" class="col-md-2 snipcss0-4-4-5">
                        <img [src]="serverPath+'logo.png'" alt=""
                            class="intro-image fadeInLeft wow animated snipcss0-5-5-6 style-Q3QGT" id="style-Q3QGT">

                    </div>
                    <div fxLayout="column"  fxFlex="75"  fxLayoutGap="5px" fxLayoutAlign="space-between center" class="col-md-10 snipcss0-4-4-7"><br class="snipcss0-5-7-8"><br class="snipcss0-5-7-9"><br
                            class="snipcss0-5-7-10">
                        <h2 class="center-title snipcss0-5-7-11 style-s5VlO"  id="style-s5VlO">
                            {{data.headerNotificationContent}}</h2>
                        <p class="intro-content text-success snipcss0-5-7-13 style-Rz7ke"  id="style-Rz7ke">
                            {{data.headerBannerCopyCoupon}}</p>
                    </div>
                </div>
            </div>
        </div>
            <div class="header-main" fxLayoutAlign="center center">
                    <calsoft-Menu [data]="data"  [type]="'one'" [categoryArray]="item">
                    </calsoft-Menu>
            </div>
    </div>
    <div fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
        fxLayoutAlign="space-between center" style="z-index: 1000;">
        <div class=" theme-container" style="height:70px;" fxLayoutAlign="space-around center">
            <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="item"></calsoft-Menu>

        </div>

    </div>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>

</div>