import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReviewRatingRoutingModule } from './review-rating-routing.module';
import { ReviewRatingComponent } from './review-rating.component';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { DirectiveModule } from 'src/app/shared/utils/directive/directive.module';
import { RatingComponent } from './Rating/Rating.component';



@NgModule({
  declarations: [ReviewRatingComponent,RatingComponent],
  imports: [
    CommonModule,
    ReviewRatingRoutingModule,
    CustomFlexLayoutModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    DirectiveModule
  ],
  exports:[
    ReviewRatingComponent,
    RatingComponent
  ]
})
export class ReviewRatingModule { }
