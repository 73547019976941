<div *ngIf="display">
  <div fxHide fxShow.gt-md class="theme-container">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container12">
        <div class="row">
          <div class="col-lg-6" fxLayoutAlign="start center">
            <div class="header-contact">
              <ul>
                <li>Welcome to Our store {{siteName}}
                </li>
                <li *ngIf="data.showContactNumber==1" (click)="callPhone()" style="cursor: pointer;"><i
                    class="fa fa-phone" aria-hidden="true"></i>Call Us: {{data.storePhoneNumber}}</li>
              </ul>
              
            </div>
          </div>
          <div class="col-lg-6" fxLayoutAlign="end center">
            <calsoft-cart-icon [data]="data"   ></calsoft-cart-icon>
            <div *ngIf="data.disableLoginHeader==0">
              <div *ngIf="!userLoggedIn" (click)="SignIn('login')" fxLayoutAlign="end center">
                <button mat-flat-button style="color: black;background: no-repeat;" *ngIf="!userLoggedIn">LOGIN/SIGNUP
                </button>
              </div>
            </div>

            <div *ngIf="url!='/account/wishlist'&& userLoggedIn">
              <button class="true" (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
                <i class="material-icons notranslate">
                  favorite_border
                </i>
              </button>
            </div>
            <div *ngIf="data.disableLoginHeader==0">
            <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center" 
              *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
              </div>


          </div>
        </div>
      </div>
    </div>
    <div class="container12 theme-container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-left">

              <div *ngIf="data.showLeftMenu==1" class="theme-container">

                <calsoft-left-menu [categoryArray]="item"></calsoft-left-menu>

              </div>
              <section>
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
              </section>
            </div>
            <div fxFlex="60" class="menu-right pull-right">
              <div>
                <!-- <app-menu3 [categoryArray]="item"></app-menu3> -->
                <calsoft-Menu class="padding-10" fxLayoutAlign="center center" style="z-index: 1000;" [data]="data"
                  [type]="'one'" [categoryArray]="item"></calsoft-Menu>

              </div>
              <div>

              </div>
            </div>

            <section *ngIf="data.disableSearchInHeader==0">
              <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="end center" fxFlex
                fxFlex.gt-sm="350px">
                <calsoft-search [searchbarborderRadius]="'15px'"></calsoft-search>
              </div>
            </section>
            <div fxFlex="5">

            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header" fxLayout="row"
      fxLayoutAlign="space-between center" style="  z-index: 1000;">
      <mat-toolbar-row class="theme-container" style="height: 100%;" fxLayoutAlign="space-between center">
        <section class="padding-10" style="padding-left: 7%;">
          <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

        </section>
        <section *ngIf="data.disableSearchInHeader==0">
          <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
            fxFlex.gt-sm="500px">
            <calsoft-search [searchbarborderRadius]="'15px'"></calsoft-search>
          </div>
        </section>
        <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">

          <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
            <div style="color: black" fxLayout="column">
              <small>Hello, Sign in</small>
              <span>Account & Lists</span>
            </div>
          </button>
          <div *ngIf="data.disableLoginHeader==0">
          <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center" 
            *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
            </div>
            <calsoft-cart-icon [data]="data"   ></calsoft-cart-icon>
        </section>
      </mat-toolbar-row>

    </mat-toolbar>
  </div>
</div>
<div *ngIf="!display">

  <div *ngIf="mobileHeaderType==1">
      <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

      </calsoft-mobile-header>
  </div>

  <div *ngIf="mobileHeaderType==0">
      <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

      </calsoft-mobile-header-new>
  </div>

  <div *ngIf="mobileHeaderType==2">
      <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

      </calsoft-mobile-header3>
  </div>

  <div *ngIf="mobileHeaderType==3">
      <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
      </calsoft-mobile-header4>
  </div>

  <div *ngIf="mobileHeaderType==4">
      <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
      </calsoft-mobile-header5>
  </div>

  <div *ngIf="mobileHeaderType==5">
      <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

      </calsoft-mobile-header6>
  </div>
  <div *ngIf="mobileHeaderType==6">
      <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
  
      </calsoft-mobile-header7>
  </div>
  <div *ngIf="mobileHeaderType==7">
  
      <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
  
      </calsoft-mobile-header8>
  </div>

</div>